import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popup, Position, Offset } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { Button } from 'devextreme-react/button';
import { Context } from '../../../utils/context/store/Store';
import { SHOW_NOTIFICATIONS_MODAL } from 'utils/context/store/Constants';
import './styles.scss';
import './responsive.scss';

export default function Notifications({ title = 'Notifications', routeKey }) {
  const [{ isNotificationModalShown, notifications }, dispatch] = useContext(Context);
  const navigate = useNavigate();

  console.log('notifications:: ', notifications);
  const handleCancel = () => {
    dispatch({
      type: SHOW_NOTIFICATIONS_MODAL,
      payload: false
    });
  };

  const handleNavigateToNotificationLink = useCallback(
    (redirectLink) => {
      handleCancel();
      navigate(redirectLink ? redirectLink : '/views/home');
    },
    [navigate]
  );

  return (
    <Popup
      visible={isNotificationModalShown}
      onHiding={handleCancel}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      showTitle={true}
      container={'.div__notification__container'}
      width={447}
      height={316}
      title={title}
      className="notification__container"
    >
      <Position at="bottom center" my="right top" of={'.__notification'}>
        <Offset x={10} y={25} />
      </Position>
      <ScrollView width="100%" height="100%">
        {notifications.length > 0 ? (
          notifications.map((props, index) => {
            const { title, description, redirectLink, bgColor } = props;

            return (
              <div key={`notifications-modal-${index}`} className="notifications__main">
                <h5
                  className="notification__icon notification__icon__hc __mb__0"
                  style={{ backgroundColor: bgColor }}
                >
                  {title}
                </h5>
                <h5 className="notification__text __mb__0">{description}</h5>
                <Button
                  className="__default__button__layout notification__button"
                  width={46}
                  text="View"
                  type="normal"
                  stylingMode="contained"
                  onClick={() => handleNavigateToNotificationLink(redirectLink)}
                />
              </div>
            );
          })
        ) : (
          <h4 className="__no__record__found">No record found!</h4>
        )}
      </ScrollView>
    </Popup>
  );
}
