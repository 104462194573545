import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    segments: {},
    new_elements: {},
    segmentsColumns: [],
    values: {},
    colors: [],
    structureManagement: {},
    structureManagementList: [],
    userRoles: {},
    userRolesColumns: [],
    routes: [],
    quickLinks: [],
    settings: [],
    roles: {},
    rolesColumns: [],
    userInfo: localStorage.getItem('user'),
    pagesAndPermissions: [],
    dataAccess: {},
    users: {},
    dataForDataAccess: {},
    jdPage: [],
    jdPageLevels: [],
    manageJobs: {},
    workflows: {},
    workflowsColumns: [],
    userDataAccess: {},
    myTasks: [],
    userLevelAccess: {},
    editDataAccess: false,
    editDataOfDataAccess: null,
    revisions: {},
    dashboardObject: null,
    navListing: {},
    modules: [],
    role_permissions: {},
    new_approvals: {},
    notifications: [],
    userFunctionalAccess: {},
    userWorkFlows: {},
    isLoading: false,
    hcRequestType: [],
    headcountRequest: {},
    masterOrgDivisionId: null,
    masterOrgEntityId: null,
    masterCountryId: null,
    masterOrgVerticalId: null,
    masterOrgFunctionId: null,
    masterLevelId: null,
    jobName: null,
    billableCost: false,
    workflowStepTableData: {},
    editWfStep: false,
    editDataOfWfStep: null,
    hcWorkflows: {},
    hcLeaver: {},
    groups: {},
    faVariable: {},
    newWfStep: {},
    newFaVariable: {},
    hcJdView: [],
    jdVersions: {},
    employee: {},
    attributeManagement: {},
    attributeManagementList: [],
    jobType: {},
    requestType: {},
    headcountType: {},
    tasks: {},
    clonedHCS: {},
    allHeadcounts: {},
    lights: [],
    cvCategories: [],
    clientVitals: [],
    notificationsManagement: {},
    entityClients: {},
    isNotificationModalShown: false,
    isProfileModalShown: false,
    allTransfers: {}, 
    transfers: {},
    pendingTasks: [],
    menu: [],
    recentActivity: [],
    columnPreferences: [],
    myAccount: [],
    profileSettingsRoute: [],
    isProfilePictureUpdated: false
};

export const Context = createContext(initialState);

export const Store = ({ children }) => {

    const [state, dispatch] = useReducer(Reducer, initialState);

    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};