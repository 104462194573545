import React, { memo } from 'react';
import ColumnItem from './columnItem';

const Column = memo(({ data, updateData }) => {
  return (
    <ul className="nested-list __ff__header__container__column__ul">
      {data.map((item, index) => (
        <ColumnItem key={`columns-${index}-${item.uniqueKey}`} item={item} index={index} data={data} updateData={updateData} />
      ))}
    </ul>
  );
});

export default Column;
