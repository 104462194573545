import { getUserInfo, getOSAndBrowserInfo } from './Helpers';
import request from "./Http";

let errorBoundaryRef = null;

export function setGlobalErrorBoundaryRef(ref) {
  errorBoundaryRef = ref;
}

export function setupGlobalErrorHandlers() {
  window.onerror = function (message, source, lineno, colno, error) {

    console.log("Global error caught: ", message, source, lineno, colno, error);

    if (errorBoundaryRef) {
      errorBoundaryRef.handleError(error);
      const fn = async () => await sendErrorToTeams({
        errorDetail: { message, source, lineno, colno, browserInfo: getOSAndBrowserInfo() }, error: error?.toString()
      })
      fn()
    }
  };

  window.addEventListener('unhandledrejection', function (event) {

    console.log("Unhandled promise rejection: ", event);

    if (errorBoundaryRef) {
      errorBoundaryRef.handleError(event.reason);
      const fn = async () => await sendErrorToTeams({
        error: event.reason,
        errorDetail: event
      })
      fn()
    }
  });
}

export const sendErrorToTeams = async (errorMessage) => {
  try {
    if (localStorage.getItem('_token')) {
      await request.post('fe-el', {
        userDetails: `${getUserInfo()?.id ? `${getUserInfo()?.id} - ${getUserInfo()?.name}` : 'User not found'}  (${getOSAndBrowserInfo()})`,
        error: errorMessage,
        route: window.location.href,
      });
    }

  }
  catch (error) {
    console.error('Error sending message to Microsoft Teams:', error);
  }
};
