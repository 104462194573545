import { Fragment, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Menu from './menu';
import useNavigation from 'utils/hooks/useNavigation';

const Navigation = ({ setIsHeaderNavOpen, routes, pathname, isMobileScreen }) => {
  const { setMenu, menu } = useNavigation();
  const [selectedNavLink, setSelectedNavLink] = useState(null);
  const wrapperRef = useRef(null);

  const handleOpenSubMenu = (menu = [], key = null) => {
    setSelectedNavLink(key);
    // handleSubMenu(key === null ? false : true);
    setMenu(menu || []);
    key === null && setIsHeaderNavOpen(false);
  };

  return (
    <div className="nav__menu" id="nav-menu" ref={wrapperRef}>
      <ul className="nav__list">
        {routes.map((router) => {
          const { key, type, isVisible = true, name, route = null, collapse = null } = router;

          return isVisible && type !== 'modal' ? (
            <li className="nav__item" key={`nav__item__${key}`}>
              {route && (
                <NavLink to={route} className={'nav__link'} onClick={() => handleOpenSubMenu()}>
                  {name}
                </NavLink>
              )}
              {collapse && (
                <Fragment key={`nav__submenu__${key}`}>
                  <span
                    className={`nav__link  ${selectedNavLink === key ? 'nav__active' : ''}`}
                    onClick={() => handleOpenSubMenu(collapse, key)}
                  >
                    {name}
                  </span>
                  {isMobileScreen && selectedNavLink === key && menu && menu.length > 0 && (
                    <span className="__mobile__menu__">
                      <Menu
                        {...{
                          menu,
                          setMenu,
                          activeRoute: pathname,
                          handleCloseMenuForMobile: handleOpenSubMenu
                        }}
                      />
                    </span>
                  )}
                </Fragment>
              )}
            </li>
          ) : null;
        })}
      </ul>
    </div>
  );
};

export default Navigation;
