import request from '../services/Http';
import React, { useContext, useEffect } from 'react';
import { Context } from '../context/store/Store';
import { SET_IS_LOADING, SET_SEGMENTS, SET_SEGMENTS_COLUMNS } from '../context/store/Constants';
import {
  addIndexAndKeyInNewRowObj,
  setColumnsForDataTable,
  isJSONValid,
  getPageProperties,
  getTableColumns
} from '../services/Helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIsLoading } from './useIsLoading';
import { v4 as uuidv4 } from 'uuid';

export default function useSegments(routeKey) {
  const [{ segments, pagesAndPermissions }, dispatch] = useContext(Context);
  const { isLoading, setIsLoading } = useIsLoading();

  async function getSegments() {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    dispatch({
      type: SET_SEGMENTS,
      payload: {}
    });
    try {
      const res = await request.get('segment')
      let rows = res.data.segmentRows
      const propeties = getPageProperties(pagesAndPermissions, routeKey ?? "manage-segments")
      const table = getTableColumns(propeties.page.table, routeKey ?? "manage-segments")
      const elementColumns = getTableColumns(propeties.page.table, "manage-elements")
      let uploadTemplateLink = res.data.uploadTemplate ?? null
      let orgStructureLink = res.data.orgStructureLink ?? null
      let tableName = res.data.tableName ?? null

      rows.forEach((row) => {
        row.index = row.sequence;
        row.key = row.hasOwnProperty('id') ? row.id : uuidv4();
        row.elements_count = row.elements.length;
      });

      if (segments && segments.hasOwnProperty('apiDelete') && segments.apiDelete) {
        rows = [...segments.rows, ...rows];
      }

      const dataTableData = {
        columns: table.columns,
        rows,
        newRowObject: addIndexAndKeyInNewRowObj(isJSONValid(table.row.defaultObj) ?? {}),
        apiDelete: false,
        elementColumns,
        uploadTemplateLink,
        orgStructureLink,
        tableName
      };
      dispatch({
        type: SET_SEGMENTS,
        payload: dataTableData
      });
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function updateOrCreateSegments(data) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.post('segment', { segments: data });
      toast.success(res.data.data);
      await getSegments();
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function deleteSegment(segments) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.delete('segment', {
        data: {
          segments: segments
        }
      });
      await getSegments();
      toast.success(res.data.data);
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  function clearComponentSegments() {
    dispatch({ type: SET_SEGMENTS, payload: {} });
  }

  return { getSegments, updateOrCreateSegments, deleteSegment, clearComponentSegments, isLoading };
}
