import {
    SET_SEGMENTS,
    SET_SEGMENTS_COLUMNS,
    SET_VALUES,
    SET_COLORS,
    SET_STRUCTURE_MANAGEMENT,
    SET_STRUCTURE_MANAGEMENT_TABS,
    SET_USER_ROLES,
    SET_WORKFLOWS,
    SET_WORKFLOWS_COLUMNS,
    SET_JD_PAGE,
    SET_JD_PAGE_LEVELS,
    SET_MANAGE_JOBS,
    SET_DATA_ACCESS,
    SET_USERS,
    SET_ROUTES,
    SET_USER_INFO,
    SET_PAGES_WITH_PERMISSIONS,
    SET_USER_ROLES_COLUMNS,
    SET_ROLES,
    SET_ROLES_COLUMNS,
    SET_DATA_TO_CREATE_DATA_ACCESS,
    SET_USER_DATA_ACCESS,
    SET_NAV_LISTING,
    SET_HOMEPAGE_TASKS,
    SET_USER_LEVEL_ACCESS,
    SET_EDIT_DATA_ACCESS,
    SET_EDIT_DATA_OF_DATA_ACCESS,
    SET_REVISIONS,
    SET_DASHBOARD_OBJECT,
    SET_MODULES,
    SET_ROLE_PERMISSIONS,
    SET_APPROVALS,
    SET_ELEMENTS,
    SET_NOTIFICATIONS,
    SET_USER_FUNCTIONAL_ACCESS,
    SET_USER_WORK_FLOWS,
    SET_IS_LOADING,
    SET_HC_REQUEST_TYPE,
    SET_HC_REQUEST,
    SET_HC_REQ_DIVISION,
    SET_HC_REQ_ENTITY,
    SET_HC_REQ_COUNTRY,
    SET_HC_REQ_VERTICAL,
    SET_HC_REQ_FUNCTION,
    SET_HC_REQ_LEVEL,
    SET_HC_JOB_NAME,
    SET_HC_BILLABLE_COST,
    SET_WORKFLOW_STEP_TABLE_DATA,
    SET_EDIT_WF_STEP,
    SET_EDIT_DATA_OF_WF_STEP,
    SET_HC_WORKFLOWS,
    SET_HC_LEAVER, SET_GROUPS,
    SET_FA_VARIABLE,
    SET_NEW_WF_STEP,
    SET_NEW_FA_VARIABLE,
    SET_HC_JD_VIEW,
    SET_JD_VERSIONS,
    SET_EMPLOYEE,
    SET_RECRUITERS,
    SET_RECRUITMENT_STATUS,
    SET_ATTRIBUTE_MANAGEMENT,
    SET_ATTRIBUTE_MANAGEMENT_LIST,
    SET_JOB_TYPE,
    SET_REQUEST_TYPE,
    SET_HEADCOUNT_TYPE,
    SET_TASKS,
    SET_CLONED_HCS,
    SET_All_HEADCOUNTS,
    SET_LIGHTS,
    SET_CV_CATEGORIES,
    SET_CLIENT_VITAL,
    SET_CLIENT_VITALS,
    SET_MANAGE_NOTIFICATIONS,
    SET_MANAGE_ENTITY_CLIENTS,
    SHOW_NOTIFICATIONS_MODAL,
    SHOW_PROFILE_MODAL,
    SET_All_TRANSFERS,
    SET_TRANSFERS,
    SET_COLUMN_PREFERENCES,
    SET_QUICK_LINKS,
    SET_SETTINGS,
    SET_PENDING_TASK,
    SET_HEADER_MENU,
    SET_RECENT_ACTIVITY,
    SET_MY_ACCOUNT,
    SET_PROFILE_SETTINGS_ROUTE,
    SET_IS_PROFILE_PICTURE
} from "./Constants";

export default function (state, { type, payload }) {
    switch (type) {
        case SET_SEGMENTS:
            return {
                ...state,
                segments: payload
            }
        case SET_VALUES:
            return {
                ...state,
                values: payload
            }
        case SET_COLORS:
            return {
                ...state,
                colors: payload
            }
        case SET_STRUCTURE_MANAGEMENT:
            return {
                ...state,
                structureManagement: payload
            }
        case SET_STRUCTURE_MANAGEMENT_TABS:
            return {
                ...state,
                structureManagementList: payload
            }
        case SET_USER_ROLES:
            return {
                ...state,
                userRoles: payload
            }
        case SET_USER_ROLES_COLUMNS:
            return {
                ...state,
                userRolesColumns: payload
            }
        case SET_ROUTES:
            return {
                ...state,
                routes: payload
            }
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: payload
            }
        case SET_PAGES_WITH_PERMISSIONS:
            return {
                ...state,
                pagesAndPermissions: payload
            }
        case SET_ROLES:
            return {
                ...state,
                roles: payload
            }
        case SET_ROLES_COLUMNS:
            return {
                ...state,
                rolesColumns: payload
            }
        case SET_SEGMENTS_COLUMNS:
            return {
                ...state,
                segmentsColumns: payload
            }
        case SET_WORKFLOWS:
            return {
                ...state,
                workflows: payload
            }
        case SET_WORKFLOWS_COLUMNS:
            return {
                ...state,
                workflowsColumns: payload
            }
        case SET_JD_PAGE:
            return {
                ...state,
                jdPage: payload
            }
        case SET_JD_PAGE_LEVELS:
            return {
                ...state,
                jdPageLevels: payload
            }
        case SET_MANAGE_JOBS:
            return {
                ...state,
                manageJobs: payload
            }
        case SET_DATA_ACCESS:
            return {
                ...state,
                dataAccess: payload
            }
        case SET_USERS:
            return {
                ...state,
                users: payload
            }
        case SET_DATA_TO_CREATE_DATA_ACCESS:
            return {
                ...state,
                dataForDataAccess: payload
            }
        case SET_USER_DATA_ACCESS:
            return {
                ...state,
                userDataAccess: payload
            }
        case SET_HOMEPAGE_TASKS:
            return {
                ...state,
                myTasks: payload
            }
        case SET_USER_LEVEL_ACCESS:
            return {
                ...state,
                userLevelAccess: payload
            }
        case SET_EDIT_DATA_ACCESS:
            return {
                ...state,
                editDataAccess: payload
            }
        case SET_EDIT_DATA_OF_DATA_ACCESS:
            return {
                ...state,
                editDataOfDataAccess: payload
            }
        case SET_REVISIONS:
            return {
                ...state,
                revisions: payload
            }
        case SET_DASHBOARD_OBJECT:
            return {
                ...state,
                dashboardObject: payload
            }
        case SET_NAV_LISTING:
            return {
                ...state,
                navListing: payload
            }
        case SET_MODULES:
            return {
                ...state,
                modules: payload
            }
        case SET_ROLE_PERMISSIONS:
            return {
                ...state,
                role_permissions: payload
            }
        case SET_APPROVALS:
            return {
                ...state,
                new_approvals: payload
            }
        case SET_ELEMENTS:
            return {
                ...state,
                new_elements: payload
            }
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload
            }
        case SET_USER_FUNCTIONAL_ACCESS:
            return {
                ...state,
                userFunctionalAccess: payload
            }
        case SET_USER_WORK_FLOWS:
            return {
                ...state,
                userWorkFlows: payload
            }
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: payload
            }
        case SET_HC_REQUEST_TYPE:
            return {
                ...state,
                hcRequestType: payload
            }
        case SET_HC_REQUEST:
            return {
                ...state,
                headcountRequest: payload
            }
        case SET_HC_REQ_DIVISION:
            return {
                ...state,
                masterOrgDivisionId: payload
            }
        case SET_HC_REQ_ENTITY:
            return {
                ...state,
                masterOrgEntityId: payload
            }
        case SET_HC_REQ_COUNTRY:
            return {
                ...state,
                masterCountryId: payload
            }
        case SET_HC_REQ_VERTICAL:
            return {
                ...state,
                masterOrgVerticalId: payload
            }
        case SET_HC_REQ_FUNCTION:
            return {
                ...state,
                masterOrgFunctionId: payload
            }
        case SET_HC_REQ_LEVEL:
            return {
                ...state,
                masterLevelId: payload
            }
        case SET_HC_JOB_NAME:
            return {
                ...state,
                jobName: payload
            }
        case SET_HC_BILLABLE_COST:
            return {
                ...state,
                billableCost: payload
            }
        case SET_WORKFLOW_STEP_TABLE_DATA:
            return {
                ...state,
                workflowStepTableData: payload
            }
        case SET_EDIT_WF_STEP:
            return {
                ...state,
                editWfStep: payload
            }
        case SET_EDIT_DATA_OF_WF_STEP:
            return {
                ...state,
                editDataOfWfStep: payload
            }
        case SET_HC_WORKFLOWS:
            return {
                ...state,
                hcWorkflows: payload
            }
        case SET_HC_LEAVER:
            return {
                ...state,
                hcLeaver: payload
            }
        case SET_GROUPS:
            return {
                ...state,
                groups: payload
            }
        case SET_FA_VARIABLE:
            return {
                ...state,
                faVariable: payload
            }
        case SET_NEW_WF_STEP:
            return {
                ...state,
                newWfStep: payload
            }
        case SET_NEW_FA_VARIABLE:
            return {
                ...state,
                newFaVariable: payload
            }
        case SET_HC_JD_VIEW:
            return {
                ...state,
                hcJdView: payload
            }
        case SET_JD_VERSIONS:
            return {
                ...state,
                jdVersions: payload
            }
        case SET_EMPLOYEE:
            return {
                ...state,
                employee: payload
            }
        case SET_RECRUITERS:
            return {
                ...state,
                recruiters: payload
            }
        case SET_RECRUITMENT_STATUS:
            return {
                ...state,
                recruitmentStatus: payload
            }
        case SET_ATTRIBUTE_MANAGEMENT:
            return {
                ...state,
                attributeManagement: payload
            }
        case SET_ATTRIBUTE_MANAGEMENT_LIST:
            return {
                ...state,
                attributeManagementList: payload
            }
        case SET_JOB_TYPE:
            return {
                ...state,
                jobType: payload
            }
        case SET_REQUEST_TYPE:
            return {
                ...state,
                requestType: payload
            }
        case SET_HEADCOUNT_TYPE:
            return {
                ...state,
                headcountType: payload
            }
        case SET_TASKS:
            return {
                ...state,
                tasks: payload
            }
        case SET_CLONED_HCS:
            return {
                ...state,
                clonedHCS: payload
            }
        case SET_LIGHTS:
            return {
                ...state,
                lights: payload
            }
        case SET_CV_CATEGORIES:
            return {
                ...state,
                cvCategories: payload
            }
        case SET_CLIENT_VITAL:
            return {
                ...state,
                clientVital: payload
            }
        case SET_CLIENT_VITALS:
            return {
                ...state,
                clientVitals: payload
            }
        case SET_All_HEADCOUNTS:
            return {
                ...state,
                allHeadcounts: payload
            }
        case SET_MANAGE_NOTIFICATIONS:
            return {
                ...state,
                notificationsManagement: payload
            }
        case SET_MANAGE_ENTITY_CLIENTS:
            return {
                ...state,
                entityClients: payload
            }
        case SHOW_NOTIFICATIONS_MODAL: 
            return{
                ...state,
                isNotificationModalShown: payload
            }
        case SHOW_PROFILE_MODAL: 
            return{
                ...state,
                isProfileModalShown: payload
            }
        case SET_All_TRANSFERS:
            return {
                ...state,
                allTransfers: payload
            }
        case SET_TRANSFERS:
            return {
                ...state,
                transfers: payload
            }
        case SET_COLUMN_PREFERENCES:
            return {
                ...state,
                columnPreferences: payload
            }
        case SET_QUICK_LINKS:
            return {
                ...state,
                quickLinks: payload
            }
        case SET_SETTINGS:
            return {
                ...state,
                settings: payload
            }
        case SET_PENDING_TASK:
            return {
                ...state,
                pendingTasks: payload
            }
        case SET_HEADER_MENU:
            return {
                ...state,
                menu: payload
            }
        case SET_RECENT_ACTIVITY:
            return {
                ...state,
                recentActivity: payload
            }
        case SET_MY_ACCOUNT:
            return {
                ...state,
                myAccount: payload
            }
        case SET_PROFILE_SETTINGS_ROUTE:
            return {
                ...state,
                profileSettingsRoute: payload
            }
        case SET_IS_PROFILE_PICTURE:
            return {
                ...state,
                isProfilePictureUpdated: payload
            }
        default:
            return state;
    }
};