import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';
import { client, division, currency, years, handleActionsEnum } from './constants';

function ActionButtons({
  save,
  saveAsExcel,
  setFullScreen,
  handleAction,
  division: divisionValue,
  client: clientValue,
  year1: year1Value,
  year2: year2Value,
  currency: currencyValue
}) {
  return (
    <div className="action__container">
      <div className="content">
        <div className="inner__content">
          <SelectBox
            className="selectBox-btn"
            searchEnabled={true}
            showClearButton={true}
            placeholder={'HFM'}
            valueExpr="ID"
            displayExpr="Name"
            onValueChanged={(e) => {
              console.log('@uz e.value:: ', e.value);
              handleAction(handleActionsEnum.DIVISION, e.value);
            }}
            value={divisionValue}
            dataSource={division}
          />
          <SelectBox
            className="selectBox-btn"
            searchEnabled={true}
            showClearButton={true}
            placeholder={'Client'}
            valueExpr="ID"
            displayExpr="Name"
            onValueChanged={(e) => {
              console.log('@uz e.value:: ', e.value);
              handleAction(handleActionsEnum.CLIENT, e.value);
            }}
            value={clientValue}
            dataSource={client}
          />
        </div>
        <div className="inner__content">
          <SelectBox
            className="selectBox-btn"
            searchEnabled={true}
            showClearButton={true}
            placeholder={'Currency'}
            valueExpr="ID"
            displayExpr="Name"
            onValueChanged={(e) => {
              console.log('@uz e.value:: ', e.value);
              handleAction(handleActionsEnum.CURRENCY, e.value);
            }}
            value={currencyValue}
            dataSource={currency}
          />
        </div>
      </div>
      {divisionValue && clientValue && (
        <>
          <div className="content">
            <SelectBox
              className="selectBox-btn"
              searchEnabled={true}
              showClearButton={true}
              placeholder={'Year'}
              onValueChanged={(e) => {
                console.log('@uz e.value:: ', e.value);
                handleAction(handleActionsEnum.YEAR_1, e.value);
              }}
              value={year1Value}
              dataSource={years}
            />
            {/* <SelectBox
              className="selectBox-btn"
              searchEnabled={true}
              showClearButton={true}
              placeholder={'Year'}
              onValueChanged={(e) => {
                console.log('@uz e.value:: ', e.value);
                handleAction(handleActionsEnum.YEAR_2, e.value);
              }}
              value={year2Value}
              dataSource={years}
            /> */}
          </div>
          <div className="content">
            <Button text="Save" onClick={save} />
            <Button text={`Extract`} onClick={saveAsExcel} />
            <Button text={`Submit`} onClick={saveAsExcel} />
            {setFullScreen && <Button text={`Full screen`} onClick={() => setFullScreen(true)} />}
          </div>
        </>
      )}
    </div>
  );
}

export default ActionButtons;
