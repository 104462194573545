import React, { Fragment, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Popup, Position, Offset } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { Button } from 'devextreme-react/button';
import { Context } from '../../../utils/context/store/Store';
import { SHOW_PROFILE_MODAL } from 'utils/context/store/Constants';
import { MY_ACCOUNT } from '../../../utils/constant';
import { getUserInfo, logout } from '../../../utils/services/Helpers';
import './styles.scss';
import './responsive.scss';

export default function Profile({ title = '', routeKey }) {
  const [{ isProfileModalShown, myAccount }, dispatch] = useContext(Context);
  const history = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 370 });

  const handleCancel = () => {
    dispatch({
      type: SHOW_PROFILE_MODAL,
      payload: false
    });
  };

  const positionAt = () => {
    if (isMobile) {
      return 'bottom right';
    }

    return 'bottom center';
  };

  const getUserProfileDetails = () => {
    return getUserInfo();
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
    history('/');
  };

  return (
    <Popup
      visible={isProfileModalShown}
      onHiding={handleCancel}
      dragEnabled={false}
      hideOnOutsideClick={true}
      showCloseButton={false}
      showTitle={false}
      container={'.div__profile__container'}
      width={isMobile ? 340 : 367}
      height={'auto'}
      title={title}
      className="profile__container"
    >
      <Position at={positionAt()} my={'right top'} of={'.profile__modal'}>
        <Offset x={10} y={25} />
      </Position>
      <div className="profiles__main">
        <div className="profile__avatar__name__container">
          {myAccount.map((accountList, index) => {
            const { key } = accountList;

            if (key.toLowerCase() === MY_ACCOUNT.PROFILE.toLowerCase()) {
              const { route, name: profileText } = accountList;
              const { name, username, email, avatar } = getUserProfileDetails() || {};
              return (
                <Fragment key={`my_account_profile_${index}`}>
                  <div className="profile__avatar">
                    <img
                      className={avatar ? '__profile__image__' : ''}
                      src={avatar}
                      alt={name || username || email}
                    />
                  </div>
                  <div className="profile__name__container">
                    <div className="profile__name">{name || username || email}</div>
                    <div className="profile__name__link">
                      <NavLink to={route} className="profile__link" onClick={handleCancel}>
                        {profileText}
                      </NavLink>
                    </div>
                  </div>
                </Fragment>
              );
            } else return null;
          })}
        </div>

        {myAccount.length > 0 && (
          <Fragment>
            <div className="break__solid__line"></div>

            <div className="profile__settings__link">
              {myAccount.map((settings, index) => {
                const { key, collapse } = settings;

                if (
                  key.toLowerCase() === MY_ACCOUNT.PROFILE_SETTINGS.toLowerCase() &&
                  collapse.length > 0
                ) {
                  return collapse.map((profileSetting, i) => {
                    const { name, route } = profileSetting;

                    return (
                      <NavLink
                        key={`my_account_settings_${i}_${index}`}
                        to={route}
                        className="profile__link"
                        onClick={handleCancel}
                      >
                        {name}
                      </NavLink>
                    );
                  });
                } else return null;
              })}
            </div>
          </Fragment>
        )}

        <div
          className={`break__solid__line padding__left__right__break__solid__line ${
            myAccount.length === 0 ? `__mt__15` : ``
          }`}
        ></div>

        <Button
          className="profile__sign__out__button"
          width={125}
          text="Sign out"
          type="normal"
          stylingMode="text"
          onClick={handleLogout}
        />
      </div>
    </Popup>
  );
}
