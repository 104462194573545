import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import request from '../services/Http';
import { Context } from '../context/store/Store';
import { convertToLocalDateTime, encryptObject, getLocalDateTime, initFireBase, getUserInfo, convertImageToBase64, fetchIpAddress } from '../services/Helpers';
import { SET_IS_PROFILE_PICTURE } from '../context/store/Constants';
import { MICROSOFT_AVATAR_URL } from '../constant';

export default function useLogin(msalInstance) {
  const [, dispatch] = useContext(Context);
  const history = useNavigate();

  async function handleLogin() {
    try {
      await msalInstance.initialize();
      const res = await msalInstance.loginPopup({
        scopes: ['User.Read', 'openid', 'profile', 'offline_access'],
        prompt: "select_account",
      })
      if (res) doActionIfLoginSuccess(msalInstance, res)
    } catch (e) {
      console.error('Login failed:', e);

      const errorMessage = e.toString();
      if (errorMessage.includes('access_denied')) {
        return toast.error('Access denied. Please ensure you have the necessary permissions and try again.');
      } else if (errorMessage.includes('user_cancelled')) {
        return toast.error("User cancelled the login process");
      } else if (errorMessage.includes('popup_closed')) {
        toast.error("Popup was closed before completing the login process");
      } else {
        console.error('An unknown error occurred:', errorMessage);
        return toast.error('Sorry, failed to authenticate user.')
      }
    }
  }

  function doActionIfLoginSuccess(instance, res, loadPage = false) {
    instance.setActiveAccount(res.account)
    const dateTime = convertToLocalDateTime(res.expiresOn)
    const _token = encryptObject(res.accessToken)
    if (loadPage) return {
      _token, expiry: dateTime
    }
    localStorage.setItem("_token", _token)
    localStorage.setItem("expiry", dateTime)
    fetchUserDetail(null, false, res.accessToken, dateTime, loadPage)
  }

  function storeUser(user) {
    request.post('user/profile', user).then((res) => {
      toast.success('Profile Updated Successfully');
      fetchUserDetail(user, true);
    });
  }

  function fetchUserDetail(
    userInfo,
    redirectToProfile = false,
    token = null,
    expiresOn = null,
    loadPage = false
  ) {
    request
      .post('user/find-by-email', userInfo, {
        headers: {
          Authorization: `Bearer ${token}`,
          'E-T': `${expiresOn}`
        }
      })
      .then(async (res) => {
        if (res) {
          const redirectTo = localStorage.getItem('redirectPath') ?? null;
          localStorage.setItem('loggedInTime', getLocalDateTime());
          localStorage.setItem('cleared', 'true');
          await initFireBase();
          localStorage.setItem('isLoggedIn', 'true');
          res?.data?.data
            ? localStorage.setItem('hasProfile', 'true')
            : localStorage.setItem('hasProfile', 'false');
          if (process.env.NODE_ENV !== 'development')
            res?.data?.data ? localStorage.setItem('user', encryptObject(res.data.data)) : null;
          else res?.data?.data ? localStorage.setItem('user', JSON.stringify(res.data.data)) : null;
          if (!loadPage)
            history(
              redirectTo !== null
                ? redirectTo
                : redirectToProfile
                  ? '/views/profile'
                  : '/views/home'
            );
          history(
            redirectTo !== null ? redirectTo : redirectToProfile ? '/views/profile' : '/views/home'
          );
          localStorage.removeItem('redirectPath');
          window.location.reload();
        }
      });
  }

  function fetchUserDetail(userInfo, redirectToProfile = false, token = null, expiresOn = null, loadPage = false) {
    request.post('user/find-by-email', userInfo, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'E-T': `${expiresOn}`
      }
    }).then(async (res) => {
      if (res) {
        console.log('localStorage.getItem(ipAddress)', localStorage.getItem('ipAddress'))
        console.log('await fetchIpAddress()', await fetchIpAddress())
        if (!localStorage.getItem('ipAddress')) localStorage.setItem('ipAddress', await fetchIpAddress())
        const redirectTo = localStorage.getItem('redirectPath') ?? null
        localStorage.setItem('loggedInTime', getLocalDateTime())
        localStorage.setItem('cleared', 'true')
        await initFireBase()
        localStorage.setItem('isLoggedIn', 'true')
        res?.data?.data ? localStorage.setItem('hasProfile', 'true') : localStorage.setItem('hasProfile', 'false')
        if (process.env.NODE_ENV !== 'development') res?.data?.data ? localStorage.setItem("user", encryptObject(res.data.data)) : null
        else res?.data?.data ? localStorage.setItem('user', JSON.stringify(res.data.data)) : null
        if (!loadPage) history(redirectTo !== null ? redirectTo : redirectToProfile ? '/views/profile' : '/views/home')
        history(redirectTo !== null ? redirectTo : redirectToProfile ? '/views/profile' : '/views/home')
        localStorage.removeItem('redirectPath')
        window.location.reload();
      }
    })
  }

  async function updateUserInfoInLocalStorage(img) {
    const userInfo = getUserInfo();
    userInfo.avatar =
      (img && (await convertImageToBase64(img))) ||
      'https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg';

    if (process.env.NODE_ENV !== 'development') {
      localStorage.setItem('user', encryptObject(userInfo));
    } else {
      localStorage.setItem('user', JSON.stringify(userInfo));
    }

    dispatch({
      type: SET_IS_PROFILE_PICTURE,
      payload: true
    })
  }

  async function fetchUserAvatarFromMicrosoftAccount() {
    try {
      const res = await request.get(MICROSOFT_AVATAR_URL, {
        custom: {
          skipErrorHandler: true // Custom config to bypass error handling
        }
      });
      if (res?.data) {
        updateUserInfoInLocalStorage(res.data);
      }
    } catch (e) {
      console.log('error:: fetchUserAvatarFromMicrosoftAccount', e.response.status);
      updateUserInfoInLocalStorage();
    }
  }

  return { handleLogin, storeUser, doActionIfLoginSuccess, fetchUserAvatarFromMicrosoftAccount };
}
