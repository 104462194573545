import request from '../services/Http';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Context } from '../context/store/Store';
import {
  SET_JD_VERSIONS,
  SET_IS_LOADING,
  SET_JD_PAGE,
  SET_JD_PAGE_LEVELS
} from '../context/store/Constants';
import { isJSONValid } from '../services/Helpers';
import useJobs from './useJobs';
import { useIsLoading } from './useIsLoading';
import 'react-toastify/dist/ReactToastify.css';

export default function useJobDescriptionPage() {
  const [jdStateValues, setJDStateValues] = useState([]);
  const { isLoading, setIsLoading } = useIsLoading();
  const [{ jdTransaction, jdPage, jdPageLevels, jdVersions }, dispatch] = useContext(Context);
  const { getJobs } = useJobs();
  const navigate = useNavigate();
  const search = useLocation().search;
  const dgId = new URLSearchParams(search).get('dg');

  async function getJobDescriptionPage(showLoading = true) {
    showLoading && dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const res = await request.get(`jd-transaction`);
      dispatch({ type: SET_JD_PAGE, payload: isJSONValid(res.data.data) ?? [] });
      showLoading && dispatch({ type: SET_IS_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function getJDByLevel(level) {
    try {
      const res = await request.get(`jd-transaction/${level}`);
      dispatch({
        type: SET_JD_PAGE_LEVELS,
        payload: isJSONValid(res.data.data) ?? []
      });
    } catch (e) {}
  }

  async function updateOrCreateJobDescriptionPage(data, jdType = null, route) {
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.post('jd-transaction', { body: data, type: jdType });
      await getJobs();
      toast.success(res.data.data);
      dispatch({ type: SET_IS_LOADING, payload: false });
      navigate(route);
    } catch (e) {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function getValuesByTransaction(transaction) {
    try {
      const res = await request.get(`jd-transaction/${transaction}/value`);
      let rows = isJSONValid(res.data.data) ?? [];
      let levelData = isJSONValid(res.data.levelData) ?? [];
      let jdval = {};
      rows.forEach((seg) => {
        seg.elements.forEach((ele) => {
          let elementScore = 0;
          ele.jd_values.forEach((val) => {
            jdval = {
              id: val.id,
              elementScore: val.elementScore,
              masterJdElementId: val.masterJdElementId,
              value: val.value,
              readOnly: val.readOnly,
              transactionJdId: val.transactionJdId
            };
            elementScore = val.elementScore;
          });
          ele.value = jdval;
          ele.elementScore = elementScore;
          const [levelValue] = levelData.filter((obj) => {
            return obj.masterJdElementId === ele.id;
          });
          ele.masterLevelId = levelValue?.masterLevelId || null;
        });
      });
      setJDStateValues(rows);
    } catch (e) {}
  }

  async function getJDById(id, isForJD = false, version, showLoading = true) {
    showLoading && dispatch({ type: SET_IS_LOADING, payload: true });
    dispatch({ type: SET_JD_VERSIONS, payload: {} });
    try {
      const res = await request.get(`headcount/request/jd/${id}/${version}`, {
        params: { isForJD, masterModuleId: isForJD ? 1 : 3 }
      });
      let data = res.data.data;
      let value = res.data.value;

      dispatch({
        type: SET_JD_VERSIONS,
        payload: { data: data, value: value, jdDropdownData: res.data.jdDropdownData }
      });

      if (isForJD && res) {
        await getValuesByTransaction(data.id);
      }

      showLoading && dispatch({ type: SET_IS_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function deleteJobDescriptionPage(transaction) {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const res = await request.delete(`jd-transaction/${transaction}`);
      await getJobs();
      toast.success(res.data.data);
      dispatch({ type: SET_IS_LOADING, payload: false });
      navigate('/views/manage-jobs');
    } catch (e) {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function withdrawJob(transaction) {
    try {
      const res = await request.put(`jd-transaction/${transaction}/withdraw`);
      toast.success(res.data.data);
      await getJobs();
      navigate('/views/manage-jobs');
    } catch (e) {}
  }

  function clearAllOnUnMount() {
    dispatch({ type: SET_JD_VERSIONS, payload: {} });
    dispatch({ type: SET_JD_PAGE, payload: [] });
    dispatch({ type: SET_JD_PAGE_LEVELS, payload: [] });
    setJDStateValues([]);
  }

  return {
    getJobDescriptionPage,
    getJDByLevel,
    getValuesByTransaction,
    getJDById,
    updateOrCreateJobDescriptionPage,
    deleteJobDescriptionPage,
    withdrawJob,
    clearAllOnUnMount,
    setIsLoading,
    jdStateValues,
    jdPage,
    jdPageLevels,
    jdVersions,
    isLoading
  };
}
