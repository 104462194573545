import request from "../services/Http";
import { getPageProperties, getTableColumns, isJSONValid, setColumnsForDataTable } from "../services/Helpers";
import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING, SET_REVISIONS } from "../context/store/Constants";
import { useIsLoading } from "./useIsLoading";
import { usePagination } from "./usePagination";

export default function useRevisions(routeKey) {

  const [{ revisions, pagesAndPermissions }, dispatch] = useContext(Context)
  const { isLoading, setIsLoading } = useIsLoading()
  const { totalCount, setTotalCount, hasMore, setHasMore, page, setPage, pageSize, setPageSize } = usePagination()
  const [revisionColumns, setRevisionsColumns] = useState([])
  const [filterOptions, setFilterOptions] = useState(null)

  async function fetchRevisions(filters) {
    setIsLoading(true);
    try {
      setRevisionsColumns([])
      const res = await request.post('revision/fetch-revision', filters);

      if (res) {
        const { data } = res.data;
        const rows = data
        const properties = getPageProperties(pagesAndPermissions, routeKey);
        const table = getTableColumns(properties.page.table, routeKey);
        let columns = table.columns;

        setRevisionsColumns(columns);
        dispatch({
          type: SET_REVISIONS,
          payload: { columns, rows }
        });
      }
    } catch (error) {
      console.error('Error fetching revisions:', error);
    }

    setIsLoading(false);
  }

  async function fetchFilterOptions() {

    setIsLoading(true)
    // dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.get('revision/filter-options')
      if (res) {
        setFilterOptions(res.data.data)
      }
    }
    catch (e) { }
    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  return { fetchRevisions, isLoading, revisionColumns, fetchFilterOptions, filterOptions }
}