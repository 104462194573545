export const validateDepartmentClient = (value, prevValues, userDataAccess) => {
  const { masterDepartmentId, masterClientId } = value.formula;

  if (masterDepartmentId) strategies.departmentSelected(value, prevValues, userDataAccess);
  if (masterClientId) strategies.clientSelected(value, prevValues, userDataAccess);

  return prevValues;
};

const strategies = {
  departmentSelected: (value, prevValues, userDataAccess) => {
    const departmentIds = value.transactions.map((e) => e.masterDepartmentId);
    prevValues["masterDepartmentId"] = departmentIds;
  },

  clientSelected: (value, prevValues, userDataAccess) => {
    const clientIDS = value.transactions.map((e) => e.masterClientId)
    prevValues["masterClientId"] = clientIDS;
    prevValues["masterDepartmentId"] = value.transactions.map((e) => e.masterDepartmentId);
    const departmentIds = filterAndMap(
      userDataAccess["masterClientId"],
      "masterClientId",
      "masterDepartmentId",
      clientIDS
    );
    prevValues["masterDepartmentId"] = departmentIds;
  },
};

const filterAndMap = (data, filterField, mapField, filterValues) =>
  data.filter((e) => filterValues.includes(e[filterField])).map((e) => e[mapField]);
