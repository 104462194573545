import { Fragment, useContext, useEffect, useMemo, useState, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Context } from 'utils/context/store/Store';
import useNavigation from 'utils/hooks/useNavigation';
import Navigation from 'components/Header/Navigation/navigation';
import RightNavigation from 'components/Header/Navigation/rightNavigation';
import Menu from './Navigation/menu';
import './styles.scss';
import './responsive-styles.scss';

const Header = memo(({ color, brand, brandName, routes, pathname }) => {
  const [{ isNotificationModalShown, isProfileModalShown, menu }, dispatch] = useContext(Context);
  const { handleOutsideClick, setMenu } = useNavigation();
  const isMobileScreen = useMediaQuery({ maxWidth: 991 });

  const [isHeaderNavOpen, setIsHeaderNavOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const headerClass = useMemo(() => {
    return `${!isMobileScreen && isHeaderNavOpen ? 'header__height' : ''} ${
      isHeaderNavOpen ? 'show__header' : 'hide__header'
    }`;
  }, [isHeaderNavOpen]);

  return (
    <Fragment>
      <header className={`header ${headerClass}`}>
        <nav className={`main__nav ${isHeaderNavOpen ? 'show__main__nav' : 'hide__main__nav'}`}>
          {!isMobileScreen && (
            <NavLink to="/views/home" className="nav__logo">
              <img src={brand} width="65px" height="60px" />
            </NavLink>
          )}
          <Navigation {...{ routes, pathname, setIsHeaderNavOpen, isMobileScreen }} />
          {!isMobileScreen && <RightNavigation {...{ pathname }} />}
        </nav>
        {!isMobileScreen && menu && menu.length > 0 && (
          <span className="__desktop__menu__">
            <Menu {...{ menu, setMenu, activeRoute: pathname }} />
          </span>
        )}
      </header>

      {/* mobile navigation */}
      {isMobileScreen &&
        (!isHeaderNavOpen ? (
          <div className="nav__toggle" id="nav-toggle">
            <i className="dx-icon-menu" onClick={() => setIsHeaderNavOpen(!isHeaderNavOpen)}></i>
            <NavLink to="/" className="nav__logo">
              <img src={brand} width="65px" height="60px" />
            </NavLink>
            <RightNavigation {...{ setIsHeaderNavOpen, pathname }} />
          </div>
        ) : (
          <div className="nav__close" id="nav-close">
            <i className="dx-icon-remove" onClick={() => setIsHeaderNavOpen(!isHeaderNavOpen)}></i>
            <NavLink to="/" className="nav__logo">
              <img src={brand} width="65px" height="60px" />
            </NavLink>
            <RightNavigation {...{ setIsHeaderNavOpen, pathname }} />
          </div>
        ))}
      {(menu.length > 0 || isNotificationModalShown || isProfileModalShown) && (
        <div className="header__shadow__div"></div>
      )}
    </Fragment>
  );
});

export default Header;
