import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Context } from 'utils/context/store/Store';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import useJobs from '../../utils/hooks/useJobs';
import useDataAccess from 'utils/hooks/useDataAccess';
import useJobDescriptionPage from 'utils/hooks/useJobDescriptionPage';
import {
  getUserInfo,
  isObjectEmpty,
  selectCustomStyles,
  handleDeleteRequest,
  getLevels,
  DEButton,
  reactSelectCustomPlaceHolderJD
} from 'utils/services/Helpers';
import BaseLayOut from 'components/Layout/BaseLayOut';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import FormField from 'components/FormField';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { Checkbox } from '@mui/material';
import { Editor } from 'react-draft-wysiwyg';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Swal from 'sweetalert2';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ToPrint from './ToPrint';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'antd/dist/antd.css';
import 'draft-js/dist/Draft.css';
import 'assets/datatable-css/editorstyles.css';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import useApprovals from '../../utils/hooks/useApprovals';
const _ = require('lodash');

const ViewJD = ({ routeKey, pageName }) => {
  const location = useLocation();
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const {
    getJobDescriptionPage,
    getJDByLevel,
    updateOrCreateJobDescriptionPage,
    deleteJobDescriptionPage,
    getValuesByTransaction,
    withdrawJob,
    jd_values,
    canApprove,
    getJDById,
    jdHeader
  } = useJobDescriptionPage();
  const { getUserDataAccessByModule } = useDataAccess(routeKey);
  const [{ jdPage, jdPageLevels, dataAccess, isLoading, jdView }, dispatch] = useContext(Context);
  const permissions = getPermissionsForPage(routeKey);
  const { deActivateJD } = useJobs(routeKey);
  const [formValues, setFormValues] = useState({});
  const [formSubmitValues, setFormSubmitValues] = useState({});
  const [jdData, setJDData] = useState({});
  const [copyJobObject, setCopyJobObject] = useState({});
  const [reviseJobObject, setReviseJobObject] = useState({});
  const [deletedValues, setDeletedValues] = useState([]);
  const [mainTaskColorBar, setMainTaskColorBar] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [jdSubmit, setJDSubmit] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isDataAccessValid, setIsDataAccessValid] = useState(true);
  const [routePath, setRoutePath] = useState(null);
  const [inActiveJD, setInActiveJD] = useState(null);
  const [hasState, setHasState] = useState(true);
  const [canAction, setCanAction] = useState(true);
  const [jdVersionDropdown, setJdVersionDropdown] = useState([]);
  const { transactionJDId, notificationId, version } = useParams();
  const { approveOrReject } = useApprovals();

  const navigate = useNavigate();

  const initialValues = {
    userId:
      location.state && !isObjectEmpty(location.state)
        ? location.state.userId === null
          ? getUserInfo()?.id
          : location.state.userId
        : getUserInfo()?.id,
    id: location.state && !isObjectEmpty(location.state) ? location.state.id : 0,
    approvalId:
      location.state && !isObjectEmpty(location.state) ? location.state?.approvalId : null,
    approver: location.state && !isObjectEmpty(location.state) ? location.state?.approver : null,
    workflowAccessId:
      location.state && !isObjectEmpty(location.state) ? location.state?.workflowAccessId : 0,
    masterLevelId:
      location.state && !isObjectEmpty(location.state) ? location.state.masterLevelName : '',
    masterOrgDivisionId:
      location.state && !isObjectEmpty(location.state) ? location.state.masterOrgDivisionName : '',
    masterOrgEntityId:
      location.state && !isObjectEmpty(location.state) ? location.state.masterOrgEntityName : '',
    masterCountryId:
      location.state && !isObjectEmpty(location.state) ? location.state.masterCountryName : '',
    masterOrgFunctionId:
      location.state && !isObjectEmpty(location.state) ? location.state.masterOrgFunctionName : '',
    masterOrgVerticalId:
      location.state && !isObjectEmpty(location.state) ? location.state.masterOrgVerticalName : '',
    jobName: location.state && !isObjectEmpty(location.state) ? location.state.jobName : '',
    approved: location.state && !isObjectEmpty(location.state) ? location.state.approved : false,
    status: location.state && !isObjectEmpty(location.state) ? location.state.status : false,
    isTemp:
      location.state && !isObjectEmpty(location.state)
        ? location.state.isTemp === null
          ? false
          : location.state.isTemp
        : false,
    isLive:
      location.state && !isObjectEmpty(location.state)
        ? location.state.isLive === null
          ? false
          : location.state.isLive
        : false,
    can_approve:
      location.state && !isObjectEmpty(location.state) ? location.state.can_approve : false,
    transactionJdId:
      location.state && !isObjectEmpty(location.state) ? location.state.transactionJdId : '',
    approver_comments:
      location.state && !isObjectEmpty(location.state) ? location.state.approver_comments : '',
    version: location.state && !isObjectEmpty(location.state) ? location.state.version : null,
    isArchived:
      location.state && !isObjectEmpty(location.state) ? location.state.isArchived : null
  };

  const initialSubmitValues = {
    userId:
      location.state && !isObjectEmpty(location.state)
        ? location.state.userId === null
          ? getUserInfo()?.id
          : location.state.userId
        : getUserInfo()?.id,
    id: location.state && !isObjectEmpty(location.state) ? location.state.id : null,
    approvalId:
      location.state && !isObjectEmpty(location.state) ? location.state?.approvalId : null,
    approver: location.state && !isObjectEmpty(location.state) ? location.state?.approver : null,
    workflowAccessId:
      location.state && !isObjectEmpty(location.state) ? location.state?.workflowAccessId : 0,
    masterLevelId:
      location.state && !isObjectEmpty(location.state) ? location.state.masterLevelId : '',
    masterOrgDivisionId:
      location.state && !isObjectEmpty(location.state) ? location.state.masterOrgDivisionId : '',
    masterOrgEntityId:
      location.state && !isObjectEmpty(location.state) ? location.state.masterOrgEntityId : '',
    masterCountryId:
      location.state && !isObjectEmpty(location.state) ? location.state.masterCountryId : '',
    masterOrgFunctionId:
      location.state && !isObjectEmpty(location.state) ? location.state.masterOrgFunctionId : '',
    masterOrgVerticalId:
      location.state && !isObjectEmpty(location.state) ? location.state.masterOrgVerticalId : '',
    jobName: location.state && !isObjectEmpty(location.state) ? location.state.jobName : '',
    approved: location.state && !isObjectEmpty(location.state) ? location.state.approved : false,
    status: location.state && !isObjectEmpty(location.state) ? location.state.status : false,
    isTemp:
      location.state && !isObjectEmpty(location.state)
        ? location.state.isTemp === null
          ? false
          : location.state.isTemp
        : false,
    isLive:
      location.state && !isObjectEmpty(location.state)
        ? location.state.isLive === null
          ? false
          : location.state.isLive
        : false,
    can_approve:
      location.state && !isObjectEmpty(location.state) ? location.state.can_approve : false,
    isDeactive:
      location.state && !isObjectEmpty(location.state) ? location.state.isDeactive : false,
    transactionJdId:
      location.state && !isObjectEmpty(location.state) ? location.state.transactionJdId : '',
    updatedBy: location.state && !isObjectEmpty(location.state) ? location.state.updatedBy : null,
    approver_comments:
      location.state && !isObjectEmpty(location.state) ? location.state.approver_comments : '',
    version: location.state && !isObjectEmpty(location.state) ? location.state.version : null,
    isArchived:
      location.state && !isObjectEmpty(location.state) ? location.state.isArchived : null
  };

  useEffect(() => {
    setHasState(true);
    const jdCall = async () => await getJDById(transactionJDId, true, true, version);
    jdCall();

    if (location?.state?.id) {
      const fn = async () => await getValuesByTransaction(location?.state?.id);
      fn();
    }

    if (dataAccess && isObjectEmpty(dataAccess)) {
      const fn = async () => await getUserDataAccessByModule(getUserInfo()?.id, 1);
      fn();
    }

    setFormValues({});
    setFormSubmitValues({});
    setMainTaskColorBar([]);
    setFormValues(initialValues);
    setFormSubmitValues(initialSubmitValues);
    return () => {
      // localStorage.removeItem(key)
    };
  }, [transactionJDId, notificationId]);

  useEffect(() => {
    if (jdView) setJdVersionDropdown(jdView?.jdDropdownData);
  }, [jdView]);

  useEffect(() => {
    console.log('2 - hasState', hasState);
  }, [hasState]);

  useEffect(() => {
    console.log('3 - jdPage', jdPage);
    setDataFromGenericAPI();
  }, [jdPage]);

  useEffect(() => {
    console.log('4 - jd_values', jd_values);
    setDataFromGenericAPI();
  }, [jd_values]);

  useEffect(async () => {
    console.log('5 - location', location);
    if (
      location &&
      location.state &&
      location.state.formValues &&
      location.state.formValues.id === 0
    ) {
      setJDSubmit(false);
      if (location.state.formSubmitValues.transactionJdId) {
        setFormValues(location.state.formValues);
        setFormSubmitValues(location.state.formSubmitValues);
        await getJobDescriptionPage();
        await getJDByLevel(location.state.formSubmitValues.masterLevelId);
        toast.success('JD in Draft Mode!!');
      } else {
        setFormValues(location.state.formValues);
        setFormSubmitValues(location.state.formSubmitValues);
        setJdVersionDropdown([]);
        toast.success('Job Copied Successfully!!');
      }
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    console.log('6 - formSubmitValues', formSubmitValues);
    updateColorBar();
    checkIfDataAccessIsValid();
    copyJob();
    reviseJob();
    const key = `${formSubmitValues.id} - ${formSubmitValues.version}`;
    // const canAction = localStorage.getItem(key) !== "false"
    setCanAction(
      (formSubmitValues?.isArchived === null ||
        formSubmitValues?.isArchived === 0 ||
        (formSubmitValues.hasOwnProperty('isCopy') && formSubmitValues.isCopy)) ??
        true
    );
  }, [formSubmitValues]);

  useEffect(async () => {
    console.log('7 - formErrors', formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      await submit();
    }
  }, [formErrors]);

  useEffect(() => {
    console.log('8 - jdData', jdData);
    console.log('jd', jdData);
    setJDData(jdData);
  }, [jdData]);

  useEffect(() => {
    console.log('9 - jdPageLevels', jdPageLevels);
    if (formSubmitValues.masterLevelId !== '') {
      setDataFromLevelAPI(formSubmitValues.masterLevelId);
    }
  }, [jdPageLevels]);

  useEffect(() => {
    console.log('10 - copyJobObject', copyJobObject);
  }, [copyJobObject]);
  useEffect(() => {
    console.log('11 - reviseJobObject', reviseJobObject);
  }, [reviseJobObject]);
  useEffect(() => {
    console.log('12 - isSubmitting', isSubmitting);
  }, [isSubmitting]);
  useEffect(() => {
    console.log('13 - routePath', routePath);
  }, [routePath]);
  useEffect(() => {
    console.log('14 - mainTaskColorBar', mainTaskColorBar);
  }, [mainTaskColorBar]);
  useEffect(() => {
    console.log('15 - inActiveJD', inActiveJD);
  }, [inActiveJD]);
  useEffect(() => {
    console.log('16 - dataAccess', dataAccess);
  }, [dataAccess]);
  useEffect(() => {
    console.log('17 - isDataAccessValid', isDataAccessValid);
  }, [isDataAccessValid]);
  useEffect(() => {
    console.log('18 - initialValues', initialValues);
  }, [initialValues]);
  useEffect(() => {
    console.log('19 - initialSubmitValues', initialSubmitValues, canAction);
    if (
      initialSubmitValues &&
      initialSubmitValues.masterOrgDivisionId !== '' &&
      formSubmitValues &&
      formSubmitValues.masterOrgDivisionId === ''
    ) {
      setFormValues(initialValues);
      setFormSubmitValues(initialSubmitValues);
    }
    if (jdView) setJdVersionDropdown(jdView?.jdDropdownData);
  }, [initialSubmitValues]);
  useEffect(() => {}, [dataAccess]);
  useEffect(() => {}, [formValues]);

  // handle form submit
  const submit = async () => {
    if (!formSubmitValues.values || formSubmitValues.id === 0) {
      formSubmitValues.values = jdData;
    }
    formSubmitValues.deletedVal = deletedValues;
    await updateOrCreateJobDescriptionPage(formSubmitValues, jdSubmit, routePath);
  };

  // handle form submit button
  const handleSubmit = (e) => {
    formSubmitValues.updatedBy =
      formSubmitValues.userId !== getUserInfo()?.id ? getUserInfo()?.id : null;
    formSubmitValues.values = jdData;
    setRoutePath('/views/manage-jobs');
    setFormErrors(validate(formSubmitValues, 'submit'));
    setIsSubmitting(true);
  };

  // handle save as draft button
  const handleSaveDraft = (e) => {
    setRoutePath('/views/manage-jobs');
    formSubmitValues.updatedBy =
      formSubmitValues.userId !== getUserInfo()?.id ? getUserInfo()?.id : null;
    setFormErrors(validate(formSubmitValues));
    setIsSubmitting(true);
  };

  // check data access validation
  const checkIfDataAccessIsValid = async () => {
    if (dataAccess && isObjectEmpty(dataAccess)) {
      await getUserDataAccessByModule(getUserInfo()?.id, 1);
    } else {
      setIsDataAccessValid(!dataAccess?.limitedDataAccess);
    }
  };

  // handle form validation
  const validate = (values, type) => {
    let errors = {};
    let colorCheck = 0;

    if (!isDataAccessValid) {
      errors.copyJob =
        'You do not have access to the data in Job Summary. Please re-select data from the dropdown!';
    }

    if (!values.masterOrgDivisionId) {
      errors.masterOrgDivisionId = 'Division cannot be blank';
    }

    if (!values.masterOrgVerticalId) {
      errors.masterOrgVerticalId = 'Vertical cannot be blank';
    }

    if (!values.masterOrgFunctionId) {
      errors.masterOrgFunctionId = 'Function cannot be blank';
    }

    if (!values.masterLevelId) {
      errors.masterLevelId = 'Level cannot be blank';
    }

    if (!values.jobName) {
      errors.jobName = 'Job Name cannot be blank';
    }

    if (type === 'submit' || type === 'reject') {
      if (jdData && jdData.length > 0) {
        const submitJDData = jdData;
        submitJDData.map((seg) => {
          seg.elements.map((ele) => {
            const contentBlocks = htmlToDraft(
              ele.value != null ? (ele.value.value != null ? ele.value.value : '') : ''
            );
            const contentState = ContentState.createFromBlockArray(contentBlocks);
            const rawHtml = convertToRaw(contentState);
            if (ele.mandatory) {
              if ((rawHtml.blocks.length === 1) & (rawHtml.blocks[0].text === '')) {
                errors[ele.id] = 'Value cannot be blank';
              }
            }
          });
        });
      }
    }

    if (Object.keys(errors).length !== 0) {
      window.scrollTo(0, 0);
      if (colorCheck === 1) {
        toast.error('Main Tasks weightage bar should be 100% accross. Please divide accordingly!!');
      } else if (!isDataAccessValid) {
        toast.error(errors.copyJob);
      } else {
        toast.error('Please enter data');
      }
    } else {
      setJDSubmit(false);
      if (type === 'submit') {
        setJDSubmit(true);
      }
      setIsSubmitting(true);
    }

    return errors;
  };

  // handle upsert
  const upsert = (array, item, type = null) => {
    let i = null;

    if (type === 'deletedVal') {
      i = array.findIndex((_item) => _item.id === item.id);
    } else {
      i = array.findIndex((_item) => _item.masterJdElementId === item.masterJdElementId);
    }

    if (i > -1) array[i] = item;
    else array.push(item);

    return array;
  };

  // handle copy job button
  const copyJob = () => {
    if (formSubmitValues && !isObjectEmpty(formSubmitValues)) {
      setJdVersionDropdown([]);
      setCopyJobObject({
        formValues: {
          ...formValues,
          id: 0,
          userId: getUserInfo()?.id,
          isLive: false,
          isTemp: false,
          status: false,
          approved: false,
          transactionJdId: null,
          values: jdData,
          isCopy: true
        },
        formSubmitValues: {
          ...formSubmitValues,
          id: 0,
          userId: getUserInfo()?.id,
          isLive: false,
          isTemp: false,
          status: false,
          approved: false,
          transactionJdId: null,
          values: jdData,
          isCopy: true
        }
      });
    }
  };

  // handle revise job
  const reviseJob = () => {
    if (formSubmitValues && !isObjectEmpty(formSubmitValues)) {
      setReviseJobObject({
        formValues: {
          ...formValues,
          id: 0,
          transactionJdId: formSubmitValues.transactionJdId ?? formSubmitValues.id,
          status: false,
          approved: false,
          isTemp: false,
          isLive: false,
          values: jdData
        },
        formSubmitValues: {
          ...formSubmitValues,
          id: 0,
          transactionJdId: formSubmitValues.transactionJdId ?? formSubmitValues.id,
          status: false,
          approved: false,
          isTemp: false,
          isLive: false,
          values: jdData
        }
      });
    }
  };

  // get entities by division
  const getEntitiesByDivision = () => {
    const entities = [];
    dataAccess?.masterOrgDivisionId?.length && dataAccess?.masterOrgEntityId?.length
      ? dataAccess?.masterOrgEntityId?.map((entity) => {
          if (formSubmitValues.masterOrgDivisionId === entity.masterOrgDivisionId)
            entities.push(entity);
        })
      : [];
    return entities.length > 0
      ? entities.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
      : [];
  };

  // get country by entities or division
  const getCountryByEntitiesOrDivision = () => {
    let countries = [];
    const jdCountries = dataAccess?.masterCountryId?.length ? dataAccess.masterCountryId : [];
    if (formSubmitValues.masterOrgEntityId) {
      const filteredCountry =
        jdCountries.filter((b) => b.masterOrgEntityId === formSubmitValues.masterOrgEntityId) ?? [];
      countries = [...new Map(filteredCountry.map((item) => [item.id, item])).values()];
    } else {
      const filteredCountry =
        jdCountries.filter((b) => b.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId) ??
        [];
      countries = [...new Map(filteredCountry.map((item) => [item.id, item])).values()];
    }
    return countries.length > 0
      ? countries.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
      : [];
  };

  // get verticals by entity or country
  const getVerticalsByEntityOrCountry = () => {
    let verticals = [];
    let filteredVerticals = dataAccess?.masterOrgVerticalId?.length
      ? dataAccess.masterOrgVerticalId
      : [];
    if (formSubmitValues.masterCountryId !== '') {
      filteredVerticals =
        formSubmitValues.masterOrgEntityId !== ''
          ? filteredVerticals.filter(
              (fv) =>
                fv.masterCountryId === formSubmitValues.masterCountryId &&
                fv.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId &&
                fv.masterOrgEntityId === formSubmitValues.masterOrgEntityId
            )
          : filteredVerticals.filter(
              (fv) =>
                fv.masterCountryId === formSubmitValues.masterCountryId &&
                fv.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId
            );
      verticals = [
        ...new Map(filteredVerticals.map((item) => [item['masterOrgVerticalId'], item])).values()
      ];
    } else if (formSubmitValues.masterOrgEntityId !== '') {
      filteredVerticals = filteredVerticals.filter(
        (obj) =>
          obj.masterOrgEntityId === formSubmitValues.masterOrgEntityId &&
          obj.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId
      );
      verticals = [
        ...new Map(filteredVerticals.map((item) => [item['masterOrgVerticalId'], item])).values()
      ];
    } else {
      filteredVerticals = filteredVerticals.filter(
        (fv) => fv.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId
      );
      verticals = [
        ...new Map(filteredVerticals.map((item) => [item['masterOrgVerticalId'], item])).values()
      ];
    }
    return verticals.length > 0
      ? verticals.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
      : [];
  };

  // get capabilites by verticals
  const getCapabilitesByVerticals = () => {
    let func = [];
    if (
      formSubmitValues.masterOrgDivisionId &&
      formSubmitValues.masterOrgEntityId &&
      formSubmitValues.masterOrgVerticalId
    ) {
      const filteredCap = dataAccess?.masterOrgFunctionId?.length
        ? dataAccess.masterOrgFunctionId.filter(
            (cap) =>
              cap.masterOrgVerticalId === formSubmitValues.masterOrgVerticalId &&
              cap.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId &&
              cap.masterOrgEntityId === formSubmitValues.masterOrgEntityId
          )
        : [];
      func = [...new Map(filteredCap.map((item) => [item['masterOrgFunctionId'], item])).values()];
    } else if (formSubmitValues.masterOrgVerticalId) {
      const filteredCap = dataAccess?.masterOrgFunctionId?.length
        ? dataAccess.masterOrgFunctionId.filter(
            (cap) =>
              cap.masterOrgVerticalId === formSubmitValues.masterOrgVerticalId &&
              cap.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId
          )
        : [];
      func = [...new Map(filteredCap.map((item) => [item['masterOrgFunctionId'], item])).values()];
    }
    console.table('func', func);
    return func.length > 0
      ? func.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
      : [];
  };

  // set data from generic API
  const setDataFromGenericAPI = () => {
    let jdGenericData = [];
    let colorbarObject = [];

    if (transactionJDId) {
      jdGenericData = jd_values;
    } else {
      jdGenericData = jdPage;
    }

    if (formSubmitValues.isTemp) {
      let oldData = formSubmitValues && formSubmitValues.values ? [...formSubmitValues.values] : [];
      if (jdGenericData.length > 0) {
        jdGenericData.map((segment) => {
          segment.elements.map((element, index) => {
            oldData.map((oldseg) => {
              oldseg.elements.map((oldele) => {
                if (element && element.value && element.value.value === '') {
                  oldData.map((oldseg) => {
                    oldseg.elements.map((oldele) => {
                      if (oldele.id === element.id) {
                        element.displayValue = oldele.displayValue;
                        element.value = oldele.value;
                      }
                    });
                  });
                }

                if (element.id === oldele.id) {
                  element.elementScore = oldele.elementScore;
                }

                const contentBlocks = htmlToDraft(
                  element && element.value && element.value.value != null ? element.value.value : ''
                );
                const contentState = ContentState.createFromBlockArray(contentBlocks);
                const rawHtml = convertToRaw(contentState);

                element.displayValue = rawHtml;
                if (element.elementScore === undefined) {
                  element.elementScore = 0;
                }
                if (element.value === null) {
                  element.value = {
                    readOnly: false,
                    value: ''
                  };
                }
                if (segment.mainTask) {
                  colorbarObject = upsert(mainTaskColorBar, {
                    masterJdElementId: element.id,
                    value: element.elementScore,
                    color: element.color,
                    showPercentage: element.elementScore != 0 ? true : false,
                    textColor: 'black',
                    fontSize: 12
                  });
                }
              });
            });
          });
        });
      }
    } else {
      if (jdGenericData.length > 0) {
        jdGenericData.map((segment) => {
          segment.elements.map((element, index) => {
            const contentBlocks = htmlToDraft(
              element && element.value && element.value.value != null ? element.value.value : ''
            );
            const contentState = ContentState.createFromBlockArray(contentBlocks);
            const rawHtml = convertToRaw(contentState);

            element.displayValue = rawHtml;
            if (element.elementScore === undefined) {
              element.elementScore = 0;
            }
            if (element.value === null) {
              element.value = {
                readOnly: false,
                value: ''
              };
            }
            if (segment.mainTask) {
              let cBar = [...mainTaskColorBar];
              colorbarObject = upsert(cBar, {
                masterJdElementId: element.id,
                value: element.elementScore,
                color: element.color,
                showPercentage: element.elementScore != 0 ? true : false,
                textColor: 'black',
                fontSize: 12
              });
            }
          });
        });
        if (colorbarObject.length > 0) {
          setMainTaskColorBar(colorbarObject);
        }
      }
    }
    setJDData(jdGenericData);
  };

  // handle insertAt
  const insertAt = (array, index, levId, ...elementsArray) => {
    let ele = [];
    let flag = 0;

    if (array && array.length > 0) {
      array.map((oldseg, ind) => {
        elementsArray.map((newseg) => {
          if (oldseg.id === newseg.id) {
            oldseg.elements.map((oldele, oldelei) => {
              newseg.elements.map((newele) => {
                if (oldele.id === newele.id) {
                  if (newele.masterLevelId === levId) {
                    oldele.displayValue = newele.displayValue;
                    oldele.masterLevelId = newele.masterLevelId;
                    oldele.value.readOnly = newele.value.readOnly;
                    oldele.value.value = newele.value.value;
                  }
                }
                flag = 1;
              });
            });
          }
        });
      });

      if (flag !== 1) {
        array.splice(index, 0, ...elementsArray);
      }

      return array;
    }
  };
  // update color bar
  const updateColorBar = () => {
    if (jdData && jdData.length > 0) {
      let updatedColorbarObject = [];
      jdData.map((segment) => {
        segment.elements.map((element) => {
          if (segment.mainTask) {
            let cBar = [...mainTaskColorBar];
            updatedColorbarObject = upsert(mainTaskColorBar, {
              masterJdElementId: element.id,
              value: element.elementScore,
              color: element.color,
              showPercentage: element.elementScore != 0 ? true : false,
              textColor: 'black',
              fontSize: 12
            });
          }
        });
      });
    }
  };

  // set data from level API
  const setDataFromLevelAPI = (levId) => {
    const jdLevelData = jdData ?? [];
    let colorbarObject = [];

    if (jdPageLevels && jdPageLevels.length > 0) {
      jdPageLevels.map((segment, index) => {
        segment.elements.map((element) => {
          const contentBlocks = htmlToDraft(
            element && element.value && element.value.value && element.value.value != null
              ? element.value.value
              : ''
          );
          const contentState = ContentState.createFromBlockArray(contentBlocks);
          const rawHtml = convertToRaw(contentState);

          element.displayValue = rawHtml;
          element.elementScore = 0;
          element.masterLevelId = levId;

          if (formSubmitValues.isTemp) {
            let oldData =
              formSubmitValues && formSubmitValues.values ? [...formSubmitValues.values] : [];
            oldData.map((oldseg) => {
              oldseg.elements.map((oldele) => {
                if (element.id === oldele.id) {
                  element.elementScore = oldele.elementScore;
                }
              });
            });
          }

          const data = jdLevelData && jdLevelData.length > 0 ? [...jdLevelData] : [];
          if (element.value !== null) {
            const obj = insertAt(data, segment.sequence - 1, levId, segment);
          }

          if (segment.mainTask) {
            let cBar = [...mainTaskColorBar];
            colorbarObject = upsert(cBar, {
              masterJdElementId: element.id,
              value: element.elementScore,
              color: element.color,
              showPercentage: element.elementScore != 0 ? true : false,
              textColor: 'black',
              fontSize: 12
            });
          }
        });
      });
      setJDData(jdLevelData);
      if (colorbarObject.length > 0) {
        setMainTaskColorBar(colorbarObject);
      }
    } else {
      setJDData(jdLevelData);
    }
  };

  // handle job delete
  const manageDelete = () => {
    if (isDataAccessValid) {
      handleDeleteRequest(async () => await deleteJobDescriptionPage(location.state.id));
    } else {
      toast.error('You cannot delete this JD as you do not have the right data access!');
    }
  };

  // handle job withdraw button
  const manageWithdraw = () => {
    if (isDataAccessValid) {
      handleDeleteRequest(
        async () => await withdrawJob(location.state.id),
        '',
        'You want to withdraw this JD!',
        'Yes, withdraw JD!'
      );
    } else {
      toast.error('You cannot withdraw this JD as you do not have the right data access!');
    }
  };

  return (
    <BaseLayOut
      pageTitle={
        formSubmitValues.jobName !== '' ? formSubmitValues.jobName : pageName ?? `Create Job`
      }
      isForJD={true}
    >
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                {formSubmitValues.jobName !== '' ? formSubmitValues.jobName : `Create Job`}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>

        <div className="jd-container">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <MDBox py={3}>
              <Card>
                <Card>
                  <MDBox p={3}>
                    {permissions &&
                    permissions.canCreate &&
                    formSubmitValues.status &&
                    formSubmitValues.approved &&
                    !formSubmitValues.isDeactive &&
                    isDataAccessValid ? (
                      <p style={{ float: 'right' }}>
                        <Checkbox
                          disabled={!canAction ? true : formSubmitValues.status === false}
                          checked={inActiveJD ?? formSubmitValues.status}
                          value={inActiveJD ?? formSubmitValues.status}
                          onChange={(e) => {
                            Swal.fire({
                              title: 'Are you sure?',
                              // text: "This will delete any existing draft version of same combination JD after the approval.",
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Yes, dectivate JD',
                              heightAuto: false,
                              height: '200px'
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deActivateJD(location.state.id);
                                setInActiveJD(false);
                              }
                            });
                          }}
                        />
                        Active
                      </p>
                    ) : null}
                    <MDTypography variant="h5" fontWeight="medium">
                      Job Summary
                    </MDTypography>
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Select
                            inputId="divisionSelect"
                            id="divisionSelect"
                            isClearable={true}
                            placeholder={reactSelectCustomPlaceHolderJD(
                              'Please Select Division...'
                            )}
                            styles={selectCustomStyles}
                            options={
                              dataAccess?.masterOrgDivisionId?.length > 0
                                ? dataAccess?.masterOrgDivisionId.sort((a, b) =>
                                    a.value.toLowerCase().localeCompare(b.value.toLowerCase())
                                  ) ?? []
                                : []
                            }
                            value={
                              formValues.masterOrgDivisionId
                                ? {
                                    id: formSubmitValues.masterOrgDivisionId,
                                    value: formValues.masterOrgDivisionId,
                                    label: formValues.masterOrgDivisionId
                                  }
                                : null
                            }
                            getOptionValue={(option) => option.label}
                            onChange={(newValue) => {
                              if (newValue !== null) {
                                const filteredObject = dataAccess.masterOrgDivisionId.filter(
                                  (val) => val.id === newValue.id
                                );
                                setFormSubmitValues({
                                  ...formSubmitValues,
                                  masterOrgDivisionId: filteredObject[0].id,
                                  masterOrgEntityId: '',
                                  masterCountryId: '',
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                                setFormValues({
                                  ...formValues,
                                  masterOrgDivisionId: newValue.label,
                                  masterOrgEntityId: '',
                                  masterCountryId: '',
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                              } else {
                                setFormSubmitValues({
                                  ...formSubmitValues,
                                  masterOrgDivisionId: null,
                                  masterOrgEntityId: '',
                                  masterCountryId: '',
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                                setFormValues({
                                  ...formValues,
                                  masterOrgDivisionId: null,
                                  masterOrgEntityId: '',
                                  masterCountryId: '',
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                              }
                            }}
                            className={formErrors.masterOrgDivisionId && 'input-error'}
                            isDisabled={
                              !canAction
                                ? true
                                : formSubmitValues.status || formSubmitValues.isTemp
                            }
                          />
                          {formErrors.masterOrgDivisionId && (
                            <span className="error">{formErrors.masterOrgDivisionId}</span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Select
                            inputId="entitySelect"
                            id="entitySelect"
                            isClearable={true}
                            placeholder={'Please Select Entity...'}
                            styles={selectCustomStyles}
                            options={
                              formSubmitValues.masterOrgDivisionId !== '' ||
                              formSubmitValues.masterOrgDivisionId !== null
                                ? getEntitiesByDivision()
                                : null
                            }
                            value={
                              formValues.masterOrgEntityId
                                ? {
                                    id: formSubmitValues.masterOrgEntityId,
                                    value: formValues.masterOrgEntityId,
                                    label: formValues.masterOrgEntityId
                                  }
                                : null
                            }
                            getOptionValue={(option) => option.label}
                            onChange={(newValue) => {
                              if (newValue !== null) {
                                const filteredObject = getEntitiesByDivision().filter(
                                  (val) => val.id === newValue.id
                                );
                                setFormSubmitValues({
                                  ...formSubmitValues,
                                  masterOrgEntityId: filteredObject[0].id,
                                  masterCountryId: '',
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                                setFormValues({
                                  ...formValues,
                                  masterOrgEntityId: newValue.label,
                                  masterCountryId: '',
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                              } else {
                                setFormSubmitValues({
                                  ...formSubmitValues,
                                  masterOrgEntityId: '',
                                  masterCountryId: '',
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                                setFormValues({
                                  ...formValues,
                                  masterOrgEntityId: '',
                                  masterCountryId: '',
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                              }
                            }}
                            className={formErrors.masterOrgEntityId && 'input-error'}
                            isDisabled={
                              !canAction
                                ? true
                                : formSubmitValues.status ||
                                  formSubmitValues.masterOrgDivisionId === '' ||
                                  formSubmitValues.isTemp
                            }
                          />
                          {formErrors.masterOrgEntityId && (
                            <span className="error">{formErrors.masterOrgEntityId}</span>
                          )}
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Select
                            inputId="countrySelect"
                            id="countrySelect"
                            isClearable={true}
                            placeholder={'Please Select Country...'}
                            styles={selectCustomStyles}
                            options={
                              formSubmitValues.masterOrgEntityId !== null ||
                              formSubmitValues.masterOrgEntityId !== '' ||
                              formSubmitValues.masterOrgDivisionId !== null ||
                              formSubmitValues.masterOrgDivisionId !== ''
                                ? getCountryByEntitiesOrDivision()
                                : null
                            }
                            value={
                              formValues.masterCountryId
                                ? {
                                    id: formSubmitValues.masterCountryId,
                                    value: formValues.masterCountryId,
                                    label: formValues.masterCountryId
                                  }
                                : null
                            }
                            getOptionValue={(option) => option.label}
                            onChange={(newValue) => {
                              if (newValue !== null) {
                                const filteredObject = getCountryByEntitiesOrDivision().filter(
                                  (val) => val.id === newValue.id
                                );
                                setFormSubmitValues({
                                  ...formSubmitValues,
                                  masterCountryId: filteredObject[0].id,
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                                setFormValues({
                                  ...formValues,
                                  masterCountryId: newValue.label,
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                              } else {
                                setFormSubmitValues({
                                  ...formSubmitValues,
                                  masterCountryId: '',
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                                setFormValues({
                                  ...formValues,
                                  masterCountryId: '',
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                              }
                            }}
                            className={formErrors.masterCountryId && 'input-error'}
                            isDisabled={
                              !canAction
                                ? true
                                : formSubmitValues.status ||
                                  (formSubmitValues.masterOrgEntityId === '' &&
                                    formSubmitValues.masterOrgDivisionId === '') ||
                                  formSubmitValues.isTemp
                            }
                          />
                          {formErrors.masterCountryId && (
                            <span className="error">{formErrors.masterCountryId}</span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Select
                            inputId="verticalSelect"
                            id="verticalSelect"
                            isClearable={true}
                            placeholder={reactSelectCustomPlaceHolderJD(
                              'Please Select Vertical...'
                            )}
                            styles={selectCustomStyles}
                            options={
                              formSubmitValues.masterOrgDivisionId !== '' ||
                              formSubmitValues.masterOrgDivisionId !== null
                                ? getVerticalsByEntityOrCountry()
                                : null
                            }
                            value={
                              formValues.masterOrgVerticalId
                                ? {
                                    id: formSubmitValues.masterOrgVerticalId,
                                    value: formValues.masterOrgVerticalId,
                                    label: formValues.masterOrgVerticalId
                                  }
                                : null
                            }
                            getOptionValue={(option) => option.label}
                            onChange={(newValue) => {
                              if (newValue !== null) {
                                const filteredObject = getVerticalsByEntityOrCountry().filter(
                                  (val) => val.id === newValue.id
                                );
                                setFormSubmitValues({
                                  ...formSubmitValues,
                                  masterOrgVerticalId: filteredObject[0].id,
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                                setFormValues({
                                  ...formValues,
                                  masterOrgVerticalId: newValue.label,
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                              } else {
                                setFormSubmitValues({
                                  ...formSubmitValues,
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                                setFormValues({
                                  ...formValues,
                                  masterOrgVerticalId: '',
                                  masterOrgFunctionId: '',
                                  masterLevelId: ''
                                });
                              }
                            }}
                            className={formErrors.masterOrgVerticalId && 'input-error'}
                            isDisabled={
                              !canAction
                                ? true
                                : formSubmitValues.status ||
                                  formSubmitValues.masterOrgDivisionId === '' ||
                                  formSubmitValues.isTemp
                            }
                          />
                          {formErrors.masterOrgVerticalId && (
                            <span className="error">{formErrors.masterOrgVerticalId}</span>
                          )}
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Select
                            inputId="functionSelect"
                            id="functionSelect"
                            isClearable={true}
                            placeholder={reactSelectCustomPlaceHolderJD(
                              'Please Select Function...'
                            )}
                            styles={selectCustomStyles}
                            options={
                              formSubmitValues.masterOrgVerticalId !== '' ||
                              formSubmitValues.masterOrgVerticalId !== null
                                ? getCapabilitesByVerticals()
                                : []
                            }
                            value={
                              formValues.masterOrgFunctionId
                                ? {
                                    id: formSubmitValues.masterOrgFunctionId,
                                    code: formValues.masterOrgFunctionId,
                                    label: formValues.masterOrgFunctionId
                                  }
                                : null
                            }
                            onChange={(newValue) => {
                              if (newValue !== null) {
                                const filteredObject = getCapabilitesByVerticals().filter(
                                  (val) => val.id === newValue.id
                                );
                                setFormSubmitValues({
                                  ...formSubmitValues,
                                  masterOrgFunctionId: filteredObject[0].id
                                });
                                setFormValues({
                                  ...formValues,
                                  masterOrgFunctionId: newValue.label
                                });
                              } else {
                                setFormSubmitValues({
                                  ...formSubmitValues,
                                  masterOrgFunctionId: ''
                                });
                                setFormValues({ ...formValues, masterOrgFunctionId: '' });
                              }
                            }}
                            className={formErrors.masterOrgFunctionId && 'input-error'}
                            isDisabled={
                              !canAction
                                ? true
                                : (formSubmitValues.masterOrgVerticalId === '' &&
                                    getCapabilitesByVerticals() &&
                                    getCapabilitesByVerticals().length === 0) ||
                                  formSubmitValues.status ||
                                  formSubmitValues.isTemp
                            }
                          />
                          {formErrors.masterOrgFunctionId && (
                            <span className="error">{formErrors.masterOrgFunctionId}</span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Select
                            inputId="levelSelect"
                            isClearable={true}
                            id="levelSelect"
                            placeholder={reactSelectCustomPlaceHolderJD('Please Select Level...')}
                            isDisabled={
                              !canAction
                                ? true
                                : formSubmitValues.masterOrgDivisionId === null ||
                                  (formSubmitValues.masterOrgDivisionId === '' &&
                                    formSubmitValues.masterOrgFunctionId === null) ||
                                  formSubmitValues.masterOrgFunctionId === '' ||
                                  formSubmitValues.status ||
                                  formSubmitValues.isTemp
                            }
                            styles={selectCustomStyles}
                            options={getLevels(
                              dataAccess?.masterLevelId ?? [],
                              dataAccess?.masterOrgVerticalId ?? [],
                              formSubmitValues.masterOrgDivisionId !== ''
                                ? formSubmitValues.masterOrgDivisionId
                                : null,
                              formSubmitValues.masterOrgEntityId !== ''
                                ? formSubmitValues.masterOrgEntityId
                                : null,
                              formSubmitValues.masterCountryId !== ''
                                ? formSubmitValues.masterCountryId
                                : null,
                              formSubmitValues.masterOrgVerticalId !== ''
                                ? formSubmitValues.masterOrgVerticalId
                                : null,
                              [],
                              dataAccess.hasOwnProperty('LIds') ? dataAccess.LIds : []
                            )}
                            value={
                              formValues.masterLevelId
                                ? {
                                    id: formSubmitValues.masterLevelId,
                                    value: formSubmitValues.masterLevelId,
                                    label: formValues.masterLevelId
                                  }
                                : null
                            }
                            getOptionValue={(option) => option.id}
                            defaultMenuIsOpen={false}
                            onChange={async (newValue) => {
                              if (newValue !== null) {
                                if (formSubmitValues.id === null) {
                                  await getJobDescriptionPage();
                                }
                                const filteredObject = dataAccess.masterLevelId.filter(
                                  (val) => val.id === newValue.id
                                );
                                setFormSubmitValues({
                                  ...formSubmitValues,
                                  masterLevelId: filteredObject[0].id
                                });
                                setFormValues({ ...formValues, masterLevelId: newValue.label });
                                await getJDByLevel(filteredObject[0].id);
                              } else {
                                setFormSubmitValues({ ...formSubmitValues, masterLevelId: '' });
                                setFormValues({ ...formValues, masterLevelId: '' });
                              }
                            }}
                            className={formErrors.masterLevelId && 'input-error'}
                          />
                          {formErrors.masterLevelId && (
                            <span className="error">{formErrors.masterLevelId}</span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            id="JobName"
                            type="text"
                            placeholder="Please Enter Job Name...*"
                            label=""
                            defaultValue={formValues.jobName ? formValues.jobName : null}
                            inputValue={formValues.jobName ? formValues.jobName : null}
                            value={formValues.jobName ? formValues.jobName : null}
                            disabled={!canAction ? true : formSubmitValues.status}
                            onChange={(e, newValue) => {
                              setFormSubmitValues({
                                ...formSubmitValues,
                                jobName: e.target.value
                              });
                              setFormValues({ ...formValues, jobName: e.target.value });
                            }}
                            MDBoxMarginBottom={0}
                            className={`custom-text-field ${
                              formErrors.jobName ? ' input-error' : ''
                            } ${formSubmitValues.status ? ' disabled-input' : ''}`}
                          />
                          {formErrors.jobName && (
                            <span className="error">{formErrors.jobName}</span>
                          )}
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </Card>
              </Card>
            </MDBox>

            {jdData && jdData.length > 0
              ? jdData.map((segment) => {
                  return (
                    <MDBox py={3}>
                      <Card>
                        <MDBox p={3} lineHeight={1}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                              <MDTypography variant="h6" fontWeight="medium">
                                {segment.name}{' '}
                                {segment?.elements?.some((s) => s.summary && s.mandatory) ? (
                                  <span style={{ color: 'red' }}>*</span>
                                ) : (
                                  ''
                                )}
                              </MDTypography>
                            </Grid>
                          </Grid>
                          {segment.elements.map((element, index) => {
                            return (
                              <MDBox pt={2} lineHeight={1}>
                                <MDBox display="flex" justifyContent="right">
                                  {element?.value?.readOnly !== true &&
                                  permissions &&
                                  permissions.canApprove &&
                                  location &&
                                  formSubmitValues.status &&
                                  !formSubmitValues.approved &&
                                  location.state?.approvalId &&
                                  location.state.approver === location.state.workflowAccessId &&
                                  location.state.canApprove ? (
                                    <Tooltip title={'Edit'}>
                                      <DEButton
                                        hint="Edit"
                                        icon="edit"
                                        stylingMode="contained"
                                        type="default"
                                        onClick={() => {
                                          setIsEditable((editable) => !editable);
                                        }}
                                        disabled={isEditable}
                                      />
                                    </Tooltip>
                                  ) : null}
                                </MDBox>
                                <Grid container spacing={2} alignItems="center">
                                  {!element.summary ? (
                                    <Grid item xs={12}>
                                      <MDTypography
                                        variant="button"
                                        style={{ color: element.color }}
                                        fontWeight="medium"
                                      >
                                        {element.name}{' '}
                                        {element.mandatory ? (
                                          <span style={{ color: 'red' }}>*</span>
                                        ) : (
                                          ''
                                        )}{' '}
                                        <br />
                                      </MDTypography>
                                      {element.description !== '' &&
                                      element.description !== null ? (
                                        <MDTypography variant="button" fontWeight="medium">
                                          <i>{element.description}</i>
                                        </MDTypography>
                                      ) : null}
                                    </Grid>
                                  ) : null}
                                </Grid>
                                <MDBox pt={2} lineHeight={1}>
                                  <MDTypography variant="button">
                                    {(formSubmitValues.status && !isEditable) || !canAction ? (
                                      <MDBox
                                        pl={3}
                                        dangerouslySetInnerHTML={{ __html: element.value.value }}
                                      />
                                    ) : element.value && element.value.readOnly ? (
                                      <MDBox
                                        pl={3}
                                        dangerouslySetInnerHTML={{ __html: element.value.value }}
                                      />
                                    ) : (
                                      <Editor
                                        wrapperId={index}
                                        handlePastedText={() => false}
                                        stripPastedStyles={true}
                                        key={element.id}
                                        toolbar={{
                                          options: ['inline', 'list', 'textAlign'],
                                          inline: {
                                            inDropdown: false,
                                            options: ['bold', 'italic', 'underline']
                                          },
                                          list: {
                                            inDropdown: false,
                                            options: ['unordered', 'ordered']
                                          },
                                          textAlign: {
                                            inDropdown: false,
                                            options: ['left', 'center', 'right', 'justify']
                                          }
                                        }}
                                        contentState={element.displayValue}
                                        onEditorStateChange={(contentState) => {
                                          let object = jdData.map((seg) => {
                                            if (seg.id === segment.id) {
                                              seg.elements.map((ele) => {
                                                if (ele.id === element.id) {
                                                  ele.value.value = draftToHtml(
                                                    convertToRaw(contentState.getCurrentContent())
                                                  );
                                                }
                                              });
                                            }
                                            return seg;
                                          });
                                          setJDData(object);
                                        }}
                                      />
                                    )}
                                    {formErrors[element.id] && (
                                      <span className="error">{formErrors[element.id]}</span>
                                    )}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            );
                          })}
                        </MDBox>
                      </Card>
                    </MDBox>
                  );
                })
              : null}

            {!isLoading &&
            jdData &&
            jdData.length > 0 &&
            permissions &&
            (permissions.canCreate || permissions.canView) &&
            jdVersionDropdown?.length &&
            !formSubmitValues.can_approve ? (
              <MDBox
                component="span"
                m={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDBox>
                  <Select
                    inputId="jdVersionSelect"
                    id="jdVersionSelect"
                    isClearable={true}
                    placeholder="Select JD version"
                    styles={selectCustomStyles}
                    options={jdView?.jdDropdownData}
                    getOptionValue={(option) => option.label}
                    onChange={(newValue) => {
                      if (newValue !== null && newValue !== undefined) {
                        localStorage.setItem(
                          `${newValue.id} - ${newValue.version}`,
                          !!(newValue.status && newValue.isLive)
                        );
                        const currentHost =
                          window.location.origin +
                          `/views/job/${newValue.task_code}/version/${newValue?.version}`;
                        window.open(currentHost);
                      }
                    }}
                  />
                </MDBox>
                <MDBox display="flex" alignItems="center" mr={1}>
                  {
                    // if in draft
                    canAction && !formSubmitValues.status && !formSubmitValues.approved ? (
                      <>
                        <DEButton
                          text="Save As Draft"
                          disabled={isLoading}
                          onClick={handleSaveDraft}
                          useSubmitBehavior={false}
                          stylingMode="contained"
                          type="default"
                          style={{ marginRight: '10px' }}
                        />
                        <DEButton
                          text="Submit"
                          disabled={isLoading}
                          onClick={handleSubmit}
                          useSubmitBehavior={false}
                          stylingMode="contained"
                          type="success"
                          style={{ marginRight: '10px' }}
                        />
                        {!formSubmitValues.status && formSubmitValues.id !== 0 ? (
                          <DEButton
                            text="Delete"
                            disabled={isLoading}
                            onClick={manageDelete}
                            stylingMode="contained"
                            type="danger"
                            style={{ marginRight: '10px' }}
                          />
                        ) : null}
                        <DEButton
                          text="Cancel"
                          disabled={isLoading}
                          stylingMode="contained"
                          type="normal"
                          to="/views/manage-jobs"
                        />
                      </>
                    ) : // if submitted for approval
                    canAction &&
                      formSubmitValues.status &&
                      !formSubmitValues.approved &&
                      formSubmitValues.id !== 0 &&
                      formSubmitValues.userId === getUserInfo()?.id ? (
                      <>
                        <DEButton
                          text="Withdraw JD"
                          disabled={isLoading}
                          stylingMode="contained"
                          type="danger"
                          onClick={manageWithdraw}
                          style={{ marginRight: '10px' }}
                        />
                        <DEButton
                          text="Cancel"
                          disabled={isLoading}
                          stylingMode="contained"
                          type="normal"
                          to="/views/manage-jobs"
                        />
                      </>
                    ) : canAction && formSubmitValues.approved && !formSubmitValues.isDeactive ? (
                      <>
                        <PDFDownloadLink
                          document={
                            <ToPrint
                              jobName={formSubmitValues.jobName}
                              jdData={jdData}
                              division_name={formValues.masterOrgDivisionId}
                            />
                          }
                          fileName={formSubmitValues.jobName}
                        >
                          {({ loading }) => (
                            <DEButton
                              text="Export to PDF"
                              disabled={loading}
                              stylingMode="contained"
                              type="normal"
                              style={{ marginRight: '10px' }}
                            />
                          )}
                        </PDFDownloadLink>
                        <DEButton
                          text="Copy Job"
                          disabled={isLoading}
                          stylingMode="contained"
                          type="default"
                          state={copyJobObject}
                          to={`/views/job/${transactionJDId}/version/${version}`}
                          style={{ marginRight: '10px' }}
                        />
                        {isDataAccessValid ? (
                          <DEButton
                            text="Edit Job"
                            disabled={isLoading}
                            stylingMode="contained"
                            type="danger"
                            state={reviseJobObject}
                            to={`/views/job/${transactionJDId}/version/${version}`}
                            style={{ marginRight: '10px' }}
                          />
                        ) : null}
                        <DEButton
                          text="Cancel"
                          disabled={isLoading}
                          stylingMode="contained"
                          type="normal"
                          to="/views/manage-jobs"
                        />
                      </>
                    ) : (
                      <PDFDownloadLink
                        document={
                          <ToPrint
                            jobName={formSubmitValues.jobName}
                            jdData={jdData}
                            division_name={formValues.masterOrgDivisionId}
                          />
                        }
                        fileName={formSubmitValues.jobName}
                      >
                        {({ loading }) => (
                          <DEButton
                            text="Export to PDF"
                            disabled={loading}
                            stylingMode="contained"
                            type="normal"
                            style={{ marginRight: '10px' }}
                          />
                        )}
                      </PDFDownloadLink>
                    )
                  }
                </MDBox>
              </MDBox>
            ) : null}

            {!isLoading &&
            jdData &&
            jdData.length > 0 &&
            permissions &&
            (permissions.canCreate || permissions.canView) &&
            formSubmitValues.approved &&
            !formSubmitValues.isDeactive &&
            jdVersionDropdown?.length === 0 ? (
              <MDBox
                component="span"
                m={1}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <MDBox mr={1}>
                  <>
                    <PDFDownloadLink
                      document={
                        <ToPrint
                          jobName={formSubmitValues.jobName}
                          jdData={jdData}
                          division_name={formValues.masterOrgDivisionId}
                        />
                      }
                      fileName={formSubmitValues.jobName}
                    >
                      {({ loading }) => (
                        <DEButton
                          text="Export to PDF"
                          disabled={loading}
                          stylingMode="contained"
                          type="normal"
                          style={{ marginRight: '10px' }}
                        />
                      )}
                    </PDFDownloadLink>
                  </>
                  <DEButton
                    text="Copy Job"
                    stylingMode="contained"
                    type="default"
                    state={copyJobObject}
                    to={`/views/job/${transactionJDId}/version/${version}`}
                    style={{ marginRight: '10px' }}
                  />
                  {isDataAccessValid ? (
                    <DEButton
                      text="Edit Job"
                      stylingMode="contained"
                      type="danger"
                      state={reviseJobObject}
                      to={`/views/job/${transactionJDId}/version/${version}`}
                      style={{ marginRight: '10px' }}
                    />
                  ) : null}
                  <DEButton
                    text="Cancel"
                    stylingMode="contained"
                    type="normal"
                    to="/views/manage-jobs"
                  />
                </MDBox>
              </MDBox>
            ) : null}

            {!isLoading &&
            jdData &&
            jdData.length > 0 &&
            permissions &&
            permissions.canCreate &&
            jdVersionDropdown?.length === 0 ? (
              !hasState ? (
                <MDBox
                  display="flex"
                  justifyContent="right"
                  alignItems="center"
                  position="relative"
                  zIndex={1}
                  sx={{ marginBottom: 5 }}
                >
                  <MDBox mr={1}>
                    <DEButton
                      text="Save As Draft"
                      onClick={handleSaveDraft}
                      useSubmitBehavior={false}
                      stylingMode="contained"
                      type="default"
                      style={{ marginRight: '10px' }}
                    />
                    <DEButton
                      text="Submit"
                      onClick={handleSubmit}
                      useSubmitBehavior={false}
                      stylingMode="contained"
                      type="success"
                      style={{ marginRight: '10px' }}
                    />
                    <DEButton
                      text="Cancel"
                      stylingMode="contained"
                      type="normal"
                      to="/views/manage-jobs"
                    />
                  </MDBox>
                </MDBox>
              ) : !formSubmitValues.status ? (
                <MDBox
                  display="flex"
                  justifyContent="right"
                  alignItems="center"
                  position="relative"
                  zIndex={1}
                  sx={{ marginBottom: 5 }}
                >
                  <MDBox mr={1}>
                    <DEButton
                      text="Save As Draft"
                      disabled={!canAction ? true : isLoading}
                      onClick={handleSaveDraft}
                      useSubmitBehavior={false}
                      stylingMode="contained"
                      type="default"
                      style={{ marginRight: '10px' }}
                    />
                    <DEButton
                      text="Submit"
                      disabled={!canAction ? true : isLoading}
                      onClick={handleSubmit}
                      useSubmitBehavior={false}
                      stylingMode="contained"
                      type="success"
                      style={{ marginRight: '10px' }}
                    />
                    {!formSubmitValues.status && formSubmitValues.id !== 0 ? (
                      <DEButton
                        text="Delete"
                        disabled={!canAction ? true : isLoading}
                        onClick={manageDelete}
                        stylingMode="contained"
                        type="danger"
                        style={{ marginRight: '10px' }}
                      />
                    ) : null}
                    <DEButton
                      text="Cancel"
                      disabled={!canAction ? true : isLoading}
                      stylingMode="contained"
                      type="normal"
                      to="/views/manage-jobs"
                    />
                  </MDBox>
                </MDBox>
              ) : formSubmitValues.status &&
                !formSubmitValues.approved &&
                formSubmitValues.id !== 0 &&
                formSubmitValues.userId === getUserInfo()?.id ? (
                <MDBox
                  display="flex"
                  justifyContent="right"
                  alignItems="center"
                  position="relative"
                  zIndex={1}
                  sx={{ marginBottom: 5 }}
                >
                  <MDBox mr={1}>
                    <DEButton
                      text="Withdraw JD"
                      disabled={!canAction ? true : isLoading}
                      stylingMode="contained"
                      type="danger"
                      onClick={manageWithdraw}
                      style={{ marginRight: '10px' }}
                    />
                    <DEButton
                      text="Cancel"
                      disabled={!canAction ? true : isLoading}
                      stylingMode="contained"
                      type="normal"
                      to="/views/manage-jobs"
                    />
                  </MDBox>
                </MDBox>
              ) : null
            ) : null}

            {!isLoading &&
            jdData &&
            jdData.length > 0 &&
            permissions &&
            permissions.canApprove &&
            formSubmitValues.can_approve ? (
              // && formValues.status && (!formSubmitValues.approved || formSubmitValues.isDeactive) && (formSubmitValues?.approvalId && formSubmitValues?.approver === formSubmitValues?.workflowAccessId) && canApprove
              <MDBox
                display="flex"
                justifyContent="right"
                alignItems="center"
                position="relative"
                zIndex={1}
                sx={{ marginBottom: 5 }}
              >
                <MDBox mr={1}>
                  <DEButton
                    text="Approve"
                    disabled={isLoading}
                    stylingMode="contained"
                    type="success"
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      approveOrReject(
                        true,
                        [formSubmitValues],
                        'jd_transaction',
                        1,
                        () => {},
                        null,
                        'can_approve'
                      );
                    }}
                  />
                  <DEButton
                    text="Reject"
                    disabled={isLoading}
                    stylingMode="contained"
                    type="danger"
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      approveOrReject(
                        false,
                        [formSubmitValues],
                        'jd_transaction',
                        1,
                        () => {},
                        null,
                        'can_approve'
                      );
                    }}
                  />
                  <DEButton
                    text="Cancel"
                    disabled={isLoading}
                    stylingMode="contained"
                    type="normal"
                    to="/views/manage-jobs"
                  />
                </MDBox>
              </MDBox>
            ) : null}
          </form>
        </div>
      </MDBox>
    </BaseLayOut>
  );
};

export default ViewJD;
