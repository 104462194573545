export const FONT_FAMILY = 'Volte-Semibold';
export const WHITE_COLOR = '#f2efef';
export const BLACK_COLOR = '#070606';
export const RED_COLOR = '#ff0202';
export const PINK_COLOR = '#fde8f2';
export const BROWN_COLOR = '#f0ead3';
export const GREEN_COLOR = '#dff7f5';
export const APPROVE_COLOR = '#79a479';
export const REJECT_COLOR = '#d56f6c';
export const DATE = 'DATE';
export const DATE_RANGE = {
  START: 'START',
  END: 'END'
};
export const MY_ACCOUNT = {
  PROFILE: 'profile',
  PROFILE_SETTINGS: 'profile-settings'
};
export const MICROSOFT_AVATAR_URL = process.env.REACT_APP_MICROSOFT_IMAGE_URL;
export const WELCOME_NOTE_LINK_TYPE = { IMAGE: 'image', VIDEO: 'video' };
export const MY_TASK_TRANSACTION_TYPES = {
  jd_transaction: 'JD',
  hc_transaction: 'HC',
  transaction_leaver: 'Leaver',
  transaction_transfer: 'Transfer'
};
export const DATA_ACCESS = {
  JD: {
    CANNOT_DELETE_DUE_TO_DATA_ACCESS: 'You cannot delete this JD as you do not have the right data access!',
    CANNOT_WITHDRAW_DUE_TO_DATA_ACCESS: 'You cannot withdraw this JD as you do not have the right data access!'
  }
}
export const JD = {
    WANT_WITHDRAW_JD: 'You want to withdraw this JD!',
    WITHDRAW_JD: 'Yes, withdraw JD!',
    APPROVE: 'Approve JD!',
    REJECT: 'Reject JD!',
    CONFIRM_DEACTIVATE_JD: 'Yes, dectivate JD',
    DELETE_EXISTING_DRAFT: 'This will delete any existing draft version of same combination JD after the approval.'
}
export const COMMENTS = 'Please enter your comments';
export const ARE_YOU_SURE = 'Are you sure?';
