import React, { useCallback } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { DEButton } from 'utils/services/Helpers';

0;

export const LeaveCurrentPage = ({
  showDialog,
  setShowDialog,
  cancelNavigation,
  confirmNavigation
}) => {
  const handleDialogClose = useCallback(() => {
    setShowDialog(false);
  }, []);

  return (
    <Popup
      visible={showDialog}
      showCloseButton={false}
      className="__font__family"
      width={500}
      height={200}
      dragEnabled={false}
      contentRender={() => (
        <>
          <div>
            <h4 className="__font__family">There are some unsaved changes!</h4>
            <h4 className="__font__family">If you proceed your changes will be lost!</h4>
            <h4 className="__font__family">Are you sure you want to proceed?</h4>
          </div>
          <div style={{ marginTop: 10, display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
            <DEButton
              className="__default__button__layout __btn__green"
              stylingMode={'contained'}
              type={'success'}
              text={'No'}
              onClick={cancelNavigation}
            />
            <DEButton
              className="__default__button__layout __btn__red"
              stylingMode={'contained'}
              type={'danger'}
              text={'Yes'}
              onClick={confirmNavigation}
            />
          </div>
        </>
      )}
    >
      <ToolbarItem render={() => <h2 className="__font__family __mb__0">Hey, Leaving?</h2>} location="center" />
    </Popup>
  );
};

export default LeaveCurrentPage;
