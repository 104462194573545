import { useContext, useEffect } from 'react';
import request from '../services/Http';
import { renderComp, renderIcons } from '../services/NavigationHelper';
import { Context } from '../context/store/Store';
import {
  SET_MODULES,
  SET_PAGES_WITH_PERMISSIONS,
  SET_ROUTES,
  SET_QUICK_LINKS,
  SET_SETTINGS,
  SET_HEADER_MENU,
  SET_MY_ACCOUNT,
  SET_PROFILE_SETTINGS_ROUTE
} from '../context/store/Constants';
import { initFireBase } from '../services/Helpers';
import { useIsMounted } from './useIsMounted';

export default function useNavigation() {
  const [{ routes, settings, menu, profileSettingsRoute }, dispatch] = useContext(Context);
  const isMounted = useIsMounted();

  function convertFirstLetterToLower(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  const createRouteObject = (routesFromApi, pagePermissions) => {
    return routesFromApi.map((route) => {
      if (route.hasOwnProperty('icon') && route.icon !== '') {
        route.icon = renderIcons(route.icon);
      }
      if (route.hasOwnProperty('noCollapse') && route.noCollapse === true) {
        route.component = renderComp(route.comp_name, route.key, pagePermissions);
      }
      if (route.hasOwnProperty('collapse') && route.collapse.length > 0) {
        getNestedRoutes(route, pagePermissions);
      }
      if (route.route?.includes('HeadcountTypeId')) {
        route.route = route.route.replace(/:([A-Za-z]+)/g, (match, paramName) => {
          return `:${convertFirstLetterToLower(paramName)}`;
        });
      }
      return route;
    });
  };

  async function fetchNavigations() {
    // const url = localStorage.getItem('hasProfile') === "false"
    //   ? 'navigation' : `navigation?id=${getUserInfo().id}`
    const url = 'navigation';
    try {
      const res = await request.get(url);

      if (res) {
        let settingsFromApi = [];
        let myAccountFromApi = [];
        let routesFromApi = res.data.data;
        const modules = res.data.modules;
        const pagePermissions = res.data.pagePermissions;
        const quickLinks = res.data.quickLinks;
        const settings = res.data.settings;
        const myAccount = res.data.myAccount;
        if (isMounted()) {
          dispatch({
            type: SET_MY_ACCOUNT,
            payload: myAccount
          });
          dispatch({
            type: SET_QUICK_LINKS,
            payload: quickLinks
          });
          dispatch({
            type: SET_PAGES_WITH_PERMISSIONS,
            payload: pagePermissions
          });

          routesFromApi = createRouteObject(routesFromApi, pagePermissions);
          settingsFromApi = createRouteObject(settings, pagePermissions);
          myAccountFromApi = createRouteObject(myAccount, pagePermissions);
          routesFromApi.push(
            {
              isVisible: false,
              key: 'error-comp',
              name: 'Error',
              icon: renderIcons('LockOpenIcon'),
              route: 'views/not-found',
              comp_name: 'NotFound',
              component: renderComp('NotFound'),
              noCollapse: true
            },
            {
              isVisible: false,
              key: 'settings-comp',
              name: 'Error',
              icon: renderIcons('LockOpenIcon'),
              route: 'views/settings/*',
              comp_name: 'Settings',
              component: renderComp('Settings', 'settings', pagePermissions),
              noCollapse: true
            },
            // {
            //   "key": "analytics",
            //   "icon": renderIcons("AnalyticsIcon"),
            //   "name": "Analytics",
            //   "type": "modal",
            //   "noCollapse": true
            // },
            // {
            //   "key": "support-feedback-center",
            //   "icon": renderIcons("HelpIcon"),
            //   "name": "Support & Feedback Center",
            //   "type": "modal",
            //   "noCollapse": true
            // },
            { type: 'divider', key: 'divider-1' }
          );

          dispatch({
            type: SET_ROUTES,
            payload: routesFromApi
          });
          dispatch({
            type: SET_SETTINGS,
            payload: settingsFromApi
          });
          dispatch({
            type: SET_PROFILE_SETTINGS_ROUTE,
            payload: myAccountFromApi
          });
          dispatch({
            type: SET_MODULES,
            payload: modules
          });
        }
      }
      await initFireBase();
    } catch (err) {
      console.error(err);
    }
  }

  const setMenu = (menu) => dispatch({ type: SET_HEADER_MENU, payload: menu });

  const handleOutsideClick = (e) => {
    const mainMenuElement = document.querySelector('#__main__menu__id');
    const isMainMenuVisible =
      mainMenuElement &&
      mainMenuElement.getBoundingClientRect().height > 0 &&
      window.getComputedStyle(mainMenuElement).display !== 'none';

    if (
      isMainMenuVisible &&
      e.target.closest('#nav-menu') === null &&
      e.target.closest('#__main__menu__id') === null
    ) {
      setMenu([]);
    }
  };

  const getNestedRoutes = (routes, pagePermissions) => {
    // Get nested routes
    routes.collapse.map((nestedRoute) => {
      if (nestedRoute.hasOwnProperty('icon') && nestedRoute.icon !== '') {
        nestedRoute.icon = renderIcons(nestedRoute.icon);
      }
      if (nestedRoute.hasOwnProperty('noCollapse') && nestedRoute.noCollapse === true) {
        nestedRoute.component = renderComp(nestedRoute.comp_name, nestedRoute.key, pagePermissions);
      }
      if (nestedRoute.hasOwnProperty('collapse') && nestedRoute.collapse.length > 0) {
        getNestedRoutes(nestedRoute, pagePermissions);
      }
    });
  };

  return { fetchNavigations, routes, profileSettingsRoute, settings, menu, handleOutsideClick, setMenu };
}
