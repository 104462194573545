import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "utils/context";
import headerLogo from "assets/images/logos/publicismedia.png";
import { ToastContainer } from "react-toastify";
import useNavigation from "./utils/hooks/useNavigation";
import {deviceToken, getUserInfo, onMessageListener } from './utils/services/Helpers';
import "./App.css";
import "./styles.scss";
import useNotification from "./utils/hooks/useNotification";
import useLogin from "utils/hooks/useLogin";
import publicisGroupeText from "assets/images/logos/publicis-groupe-text.svg";
import Header from 'components/Header';
import Footer from 'components/Footer';
import useGlobalEventLogger from "utils/hooks/useGlobalEventLogger";
import CustomSkelton from "components/Skelton/CustomSkelton";
import { SocketContext } from './utils/context/socketContext';

const App = React.memo(() => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const { fetchNavigations, routes, profileSettingsRoute } = useNavigation();
  const { fetchUserAvatarFromMicrosoftAccount } = useLogin();
  const { getNotifications } = useNotification();
  useGlobalEventLogger(); // Initialize global event logger

  if (!Boolean(parseInt(process.env.REACT_APP_ENABLE_CONSOLE_LOG))) console.log = () => { }
  if (!Boolean(parseInt(process.env.REACT_APP_ENABLE_CONSOLE_WARN))) console.warn = () => { }
  if (!Boolean(parseInt(process.env.REACT_APP_ENABLE_CONSOLE_ERROR))) console.error = () => { }
  if (!Boolean(parseInt(process.env.REACT_APP_ENABLE_CONSOLE_TABLE))) console.table = () => { }

  const history = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const rId = new URLSearchParams(search).get('rId') ?? null;
  const dgId = new URLSearchParams(search).get('dgId') ?? null;
  const socket = useContext(SocketContext);

  useEffect(() => {
    const handlePageLoad = () => {
      // setPageLoaded(true);
      console.log('Page fully loaded');
      // Your logic after the page is fully loaded

      if (rId !== null) localStorage.setItem('rId', rId);
      if (dgId !== null) localStorage.setItem('dgId', dgId);
      const dd = async () => await fetchData();
      dd();
    };

    // Add event listener for page load
    window.addEventListener('load', handlePageLoad);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, []);

  const fetchData = async () => {
    await fetchNavigations();

    if (getUserInfo()?.id !== null && getUserInfo()?.id !== undefined) {
      fetchUserAvatarFromMicrosoftAccount();
      await getNotifications();
      socket.emit('update_device_token', {device_token: deviceToken(), userId: getUserInfo()?.id})
      // await callCreateUpdateDeviceTokenApi();
    }

    if (getUserInfo()?.id !== null && location.pathname !== '/') {
      history(location.pathname);
    }

    if (getUserInfo()?.id !== null && location.pathname === '/') {
      history('views/home');
    }

    onMessageListener().then(async (res) => {
      await getNotifications();
    });
  };

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin]
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <div className='__root__container__'>
        <ToastContainer style={{ fontSize: '14px', width: '400px' }} />
        <CustomSkelton />
        {/* <ConfirmProvider
        defaultOptions={{
          confirmationButtonProps: { autoFocus: true }
        }}
      > */}
        <CssBaseline />
        {layout === 'dashboard' && (
          <Header
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? headerLogo : headerLogo}
            brandName={publicisGroupeText}
            routes={routes}
            pathname={pathname}
          />
        )}
        {layout === 'vr' && <Configurator />}
        <div className="__container__content">
          <Routes>
            {getRoutes(routes)}
            {getRoutes(profileSettingsRoute)}
            {routes && routes.length === 2 ? (
              <Route path="*" element={<Navigate to="/views/not-found" />} />
            ) : null}
          </Routes>
        </div>
        {layout === 'dashboard' && (
          <Footer
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? headerLogo : headerLogo}
            brandName={publicisGroupeText}
            routes={routes}
            pathname={pathname}
          />
        )}
        {/* </ConfirmProvider> */}
      </div>
    </ThemeProvider>
  );
});
export default App;
