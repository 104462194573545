import React from 'react';
import './styles.scss';

const Skelton = () => {
  return (
    <div className="__skelton__container__">
      <div className="__section__">
        <div className="__lines__"></div>
        <div className="__lines__"></div>
        <div className="__lines__"></div>
      </div>
    </div>
  );
};

export default Skelton;
