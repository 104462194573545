import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import request from '../services/Http';
import { Context } from '../context/store/Store';
import { useIsLoading } from './useIsLoading';
import { SET_COLUMN_PREFERENCES, SET_MANAGE_JOBS } from '../context/store/Constants';
import { getUserInfo, isJSONValid } from '../../utils/services/Helpers';

export default function useColumnPreferences() {
  const [{ columnPreferences, pagesAndPermissions }, dispatch] = useContext(Context);
  const { isLoading, setIsLoading } = useIsLoading();
  const [columnPreferencesStoreId, setColumnPreferencesStoreId] = useState(null);

  async function checkColumnPreferencesAndUpdateTableColumns(manageJobs) {
    setIsLoading(true);
    try {
      if (columnPreferences.length > 0) {
        const columns = columnPreferences.filter(({ isChecked }) => isChecked);
        dispatch({ type: SET_MANAGE_JOBS, payload: { ...manageJobs, columns } });
      } else {
        setColumnPreferences(
          manageJobs.columns.map((props) => ({ ...props, isChecked: props.is_visible }))
        );
      }
    } catch (e) {}
    setIsLoading(false);
  }

  async function fetchColumnPreferences(routeKey, pageName) {
    setIsLoading(true);
    try {
      const res = await request.get('columns-preferences', {
        params: { pageKey: routeKey, tableKey: pageName }
      });
      if (res) {
        const columns = JSON.parse(res.data.data?.columns);
        setColumnPreferencesStoreId(res.data.data?.id);
        if (columns.length > 0) {
          setColumnPreferences(columns);
        }
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  }

  async function setColumnPreferencesData(data) {
    const columns = data?.columns ? isJSONValid(data?.columns) : [];
    setColumnPreferencesStoreId(data?.id);
    if (columns.length > 0) setColumnPreferences(columns);``
    setIsLoading(false);
  }
  async function storeColumnPreferences(routeKey, pageName, columnsList) {
    try {
      let navigationId;
      const userId = getUserInfo()?.id;
      pagesAndPermissions.forEach(({ routeKey: currentRouteKey, navigationId: navigationIdd }) => {
        if (currentRouteKey === routeKey) {
          navigationId = navigationIdd;
          return navigationId;
        }
      });
      const res = await request.post('columns-preferences', {
        data: {
          pageKey: routeKey,
          columns: columnsList,
          tableKey: pageName,
          userId,
          navigationId,
          id: columnPreferencesStoreId
        }
      });
      if (res) {
        toast.success(res.data.data)
        setColumnPreferencesStoreId(res.data.id);
      }
    } catch (e) {}
  }

  const getColumnPreferences = () => columnPreferences;

  const setColumnPreferences = (columns) =>
    dispatch({ type: SET_COLUMN_PREFERENCES, payload: columns });

  return {
    getColumnPreferences,
    setColumnPreferences,
    fetchColumnPreferences,
    checkColumnPreferencesAndUpdateTableColumns,
    storeColumnPreferences,
    setColumnPreferencesData,
    setColumnPreferencesStoreId,
    isLoading,
    columnPreferencesStoreId,
    columnPreferences
  };
}
