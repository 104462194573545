import request from "../services/Http";
import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import {
  SET_DATA_ACCESS,
  SET_DATA_TO_CREATE_DATA_ACCESS,
  SET_IS_LOADING,
} from "../context/store/Constants";
import { toast } from "react-toastify";
import { useIsLoading } from "./useIsLoading";
import { getPageProperties, fillAndExpandLevels, isJSONValid } from "../services/Helpers";
import { v4 as uuidv4 } from "uuid";

export default function useDataAccess(routeKey) {
  const [{ dataAccess, pagesAndPermissions }, dispatch] = useContext(Context);
  const { isLoading, setIsLoading } = useIsLoading();
  const [userDataAccess, setUserDataAccess] = useState({});

  /**
   * @param userId
   * @param masterModuleId
   * get user data-access by module
   **/
  async function getUserDataAccessByModule(userId = null, masterModuleId = null, skipLoading = false) {
    if (!skipLoading) {
      dispatch({ type: SET_IS_LOADING, payload: true });
    }
    dispatch({ type: SET_DATA_ACCESS, payload: {} });
    setIsLoading(true);
    try {
      const res = await request.get(`data-access/module/${userId}/${masterModuleId}`);
      if (res) {
        dispatch({
          type: SET_DATA_ACCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
    if (!skipLoading) {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function getUserDataAccessByModuleForLocalState(userId = null, masterModuleId = null) {
    try {
      const res = await request.get(`data-access/module/${userId}/${masterModuleId}`);
      if (res) {
        return res.data
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * @param userId
   * @param masterModuleId
   * get user data-access by module
   **/
  async function getUserDataAccess(userId = null, masterModuleId = null) {
    const url = !userId ? "data-access" : `data-access/${userId}/${masterModuleId}`;
    setIsLoading(true);
    dispatch({ type: SET_DATA_ACCESS, payload: {} });

    try {
      const res = await request.get(url);
      if (res) {
        if (userId) {
          const dropDownData = res.data.dropDownData;
          const properties = getPageProperties(pagesAndPermissions, routeKey);
          const dataAccessDropdowns = properties?.page?.elements?.dataAccess?.dropdowns;
          const dataAccessFormulas = properties?.page?.elements?.dataAccess?.formulas;
          function compare(a, b) {
            if (a.masterOrgVerticalId === null && b.masterOrgVerticalId !== null) {
              return 1; // a comes after b
            }
            if (a.masterOrgVerticalId !== null && b.masterOrgVerticalId === null) {
              return -1; // a comes before b
            }
            if (a.masterOrgVerticalId !== null && b.masterOrgVerticalId !== null) {
              if (a.masterOrgVerticalId < b.masterOrgVerticalId) {
                return -1; // a comes before b
              }
              if (a.masterOrgVerticalId > b.masterOrgVerticalId) {
                return 1; // a comes after b
              }
            }
            if (a.masterOrgEntityId === null && b.masterOrgEntityId !== null) {
              return 1; // a comes after b
            }
            if (a.masterOrgEntityId !== null && b.masterOrgEntityId === null) {
              return -1; // a comes before b
            }
            if (a.masterOrgEntityId !== null && b.masterOrgEntityId !== null) {
              if (a.masterOrgEntityId < b.masterOrgEntityId) {
                return -1; // a comes before b
              }
              if (a.masterOrgEntityId > b.masterOrgEntityId) {
                return 1; // a comes after b
              }
            }
            return 0; // a and b are equal
          }
          const levels = fillAndExpandLevels(
            dropDownData.masterLevelId.sort(compare),
            dropDownData.masterOrgVerticalId.map((e) => ({ ...e, verticalKey: uuidv4() }))
          );
          dropDownData["masterLevelId"] = levels.sort((a, b) => a.label.localeCompare(b.label));;
          const dataTableData = {
            columns: [],
            data: res.data?.data?.accesses?.map(d => {
              d.combinations = d.combinations.map(c => {
                c.formula = isJSONValid(c.formula)
                return c
              })
              return d
            }) ?? [],
            dropDownData: dropDownData,
            dataAccessDropdowns: dataAccessDropdowns,
            dataAccessFormulas: dataAccessFormulas,
            apiDelete: false,
          };

          setUserDataAccess(dataTableData);
        } else {
          dispatch({
            type: SET_DATA_ACCESS,
            payload: res.data,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  /**
   * get dropdown data to create data access
   **/
  async function getDataToCreateDataAccess() {
    setIsLoading(true);
    try {
      const res = await request.get("data-access/all/list");
      if (res) {
        dispatch({
          type: SET_DATA_TO_CREATE_DATA_ACCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  /**
   * @param data
   * update or create data access of user per module
   **/
  async function updateOrCreateDataAccess(data, userId, masterModuleId) {
    setIsLoading(true);
    try {
      const res = await request.post("data-access", data);
      toast.success(res.data.data);
      await getUserDataAccess(userId, masterModuleId);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  /**
   * @param accesses
   * @param userId
   * @param deleteAll
   * @param masterModuleId
   * delete data access of user by module
   **/
  async function deleteDataAccess(accesses, userId, deleteAll = false, masterModuleId) {
    setIsLoading(true);
    try {
      const res = await request.delete("data-access", {
        data: {
          userId: userId,
          deleteAll: deleteAll,
          accesses: accesses,
          masterModuleId: masterModuleId,
        },
      });
      toast.success(res.data.data);
      if (res) await getUserDataAccess(userId, masterModuleId);
    } catch (e) { }

    setIsLoading(false);
  }

  //data-access/:dataAccessId/combination/:dataAccessCombinationId
  async function deleteDataAccessCombination(
    dataAccessId,
    dataAccessCombinationId,
    userId,
    masterModuleId
  ) {
    setIsLoading(true);
    try {
      const res = await request.delete(
        `data-access/${dataAccessId}/combination/${dataAccessCombinationId}`
      );
      toast.success(res.data.data);
      if (res) await getUserDataAccess(userId, masterModuleId);
    } catch (e) { }

    setIsLoading(false);
  }

  return {
    getUserDataAccessByModule,
    getUserDataAccessByModuleForLocalState,
    getUserDataAccess,
    getDataToCreateDataAccess,
    updateOrCreateDataAccess,
    deleteDataAccess,
    isLoading,
    userDataAccess,
    deleteDataAccessCombination,
    dataAccess
  };
}
