export const getNumberOfColumnsFromHeader = (header) => {
  return header[header.length - 1].length;
};

const getExcelColumnName = (colIndex) => {
  let columnName = '';
  while (colIndex >= 0) {
    columnName = String.fromCharCode((colIndex % 26) + 65) + columnName;
    colIndex = Math.floor(colIndex / 26) - 1;
  }
  return columnName;
};

export const generateExcelColumnNames = (numberOfColumns) => {
  const columnNames = [];
  for (let i = 0; i < numberOfColumns; i++) {
    columnNames.push(getExcelColumnName(i));
  }
  return columnNames;
};

export const dataSourceForHeaderVersion = (selectedTypeHeaderVersions) =>
  selectedTypeHeaderVersions.map(({ id, isDefault }, index) => ({
    id,
    name: `Version ${index + 1}${isDefault ? ` (Default Version)` : ``}`
  }));

export const getFilteredSelectedTypeHeaderVersionFromList = (versionId, selectedTypeHeaderVersions) =>
  selectedTypeHeaderVersions.filter(({ id }) => id !== versionId);
