import request from "../services/Http";
import { useContext, useState } from "react";
import { useIsLoading } from "./useIsLoading";
import { getPageProperties, getTableColumns } from "../services/Helpers";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING } from "../context/store/Constants";
import { toast } from "react-toastify";

export default function useFreelancerManagement(routeKey){
  const [freelancers, setFreelancers] = useState({
    columns: [],
    rows: [],
    hcDetailColumns: []
  })
  const { isLoading, setIsLoading } = useIsLoading()
  const [{ pagesAndPermissions }, dispatch] = useContext(Context)

  async function getAllFreelancers(){
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.get('freelancer-management')
      const properties = getPageProperties(pagesAndPermissions, routeKey)
      const table = getTableColumns(properties.page.table, routeKey)
      const hcDetailTable = getTableColumns(properties.page.table, 'hc-details')
      let columns = table.columns
      let hcDetailColumns = hcDetailTable.columns
      let rows = res.data.data
      setFreelancers({ columns, rows, hcDetailColumns })
    }
    catch (e) {}
    finally {
      setIsLoading(false)
      dispatch({ type: SET_IS_LOADING, payload: false })
    }
  }

  async function createUpdateFreelancerBusinessFeedback(data){
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.post('freelancer-management', data)
      if (res)
      {
        toast.success(res.data.data)
        await getAllFreelancers()
      }
    }catch (e) {}
    finally {
      setIsLoading(false)
      dispatch({ type: SET_IS_LOADING, payload: false })
    }

  }

  return {
    getAllFreelancers,createUpdateFreelancerBusinessFeedback, freelancers, isLoading
  }
}
