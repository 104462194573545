import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Export,
  HeaderFilter,
  LoadPanel,
  Lookup,
  MasterDetail,
  Pager,
  Paging,
  RequiredRule,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import * as React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DEButton, getLevels, isObjectEmpty } from "utils/services/Helpers";
import { onRowExpanding, renderAttachment } from "utils/services/DatagridHelpers";
import { Context } from "utils/context/store/Store";
import {
  CustomDateBox,
  CustomDTag,
  CustomFileInput,
  CustomNumberBox,
  CustomTextArea,
  CustomTextBox,
  SelectJD,
} from "components/CustomDataGridComponents";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import useApprovals from "utils/hooks/useApprovals";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ActionDropdown from "../components/ActionDropdown";
import useManageTransfers from "utils/hooks/useManageTransfers";
import TransferDetailComponent from "../components/TransferDetailComponent";
const _ = require('lodash')

/**
 * custom component to display Supervisor details
 **/
const CustomMasterDetail = React.memo(({ props, columns, dropDownData, transferDetails, routeKey, permissions, apiCallBack, onRowExpand, dataSource, setDataSource, dgInstance, canApproveReject, selectedRowKeys, setSelectedRowKeys, pagesAndPermissions }) => {
  const [{ dataAccess }, dispatch] = useContext(Context)

  useEffect(() => { }, [dataAccess, transferDetails]);

  return <>
    {
      onRowExpand && transferDetails
        ?
        <TransferDetailComponent props={props.data} columns={columns} dropDownData={dropDownData} transferDetails={transferDetails} dataAccess={dataAccess} routeKey={routeKey} permissions={permissions} apiCallBack={apiCallBack} dataSource={dataSource} setDataSource={setDataSource} dgInstance={dgInstance} canApproveReject={canApproveReject} selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} pagesAndPermissions={pagesAndPermissions} />
        : null
    }
  </>

})
CustomMasterDetail.displayName = "CustomMasterDetail"

const AllTransfersDataGrid = React.memo(({ rows, columns, isLoading, dropDownData = null, routeKey, transactionType = null, fields, permissions, apiCallBack = null, requestIdFilter, setrIdToFilter, dataAccess, transferFormColumns }) => {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [onRowExpand, setOnRowExpand] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const dataGridRef = useRef();
  const { bulkApproveOrReject, handleBulkHoldOrReActivateRequest, bulkRollback, bulkCancelGroupRequest } = useApprovals()
  const { getTransferDetails, getAllTransfers, transferDetails, pagesAndPermissions } = useManageTransfers(routeKey)
  const masterModuleId = 7
  const navigate = useNavigate();

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => {
    setDataSource(rows);
  }, [rows]);
  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);
  useEffect(() => { }, [transferDetails]);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col, dropDownData) {
    if (col.type === "select") {
      if (!col.hasOwnProperty("filtrationKey")) {
        return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
          setCellValue={function (rowData, value) {
            if (col.hasOwnProperty("bindedTo"))
              rowData[col.bindedTo] = null;
            this.defaultSetCellValue(rowData, value);
          }}>
          {
            col.required ? <RequiredRule /> : null
          }
          <Lookup allowClearing dataSource={(options) => {
            const data = {
              store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
              paginate: true,
              pageSize: 10
            }
            return data;
          }} displayExpr="label" valueExpr="id" />
        </Column>;
      } else if (col.hasOwnProperty("filtrationKey")) {
        if (col.dataIndex === "masterOrgVerticalId") {
          return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              rowData['masterOrgVerticalId'] = null
              rowData['masterOrgFunctionId'] = null
              rowData['masterLevelId'] = []
              this.defaultSetCellValue(rowData, value);
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
              dispatch({ type: SET_HC_JD_VIEW, payload: [] })

            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getVerticalsByEntityAndDivision(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else if (col.dataIndex === "masterOrgFunctionId") {
          return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              rowData['masterOrgFunctionId'] = null
              rowData['masterLevelId'] = []
              this.defaultSetCellValue(rowData, value);
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getFunctionsByVerticals(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else if (col.dataIndex === "masterCountryId") {
          col.required = true
          return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.dataIndex === "masterCountryId") {
                rowData['masterLevelId'] = []
                rowData['masterOrgVerticalId'] = null
                rowData['masterOrgFunctionId'] = null
              }
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }

            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getCountriesByDivisionOrEntity(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else if (col.dataIndex === "masterOrgEntityId") {
          col.required = true
          return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.dataIndex === "masterOrgEntityId") {
                rowData['masterLevelId'] = []
                rowData['masterCountryId'] = null
                rowData['masterOrgVerticalId'] = null
                rowData['masterOrgFunctionId'] = null
              }
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }

            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getEntitiesByDivision(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else {
          return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return {
                store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
                filter: options.data ? [col.filtrationKey, "=", options.data[col.filtrationKey]] : null,
              };
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
      }
    }
    else if (col.type === "multi-select") {
      return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomDTag}
        cellTemplate={(container, options) => {
          const noBreakSpace = "\u00A0";
          const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
          container.textContent = text || noBreakSpace;
          container.title = text;
        }}
        calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
          if (target === "search" && typeof (filterValue) === "string") {
            return [col.dataIndex, "contains", filterValue];
          }
          return function (data) {
            return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
          };
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
        <Lookup allowClearing
          dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : null}
          displayExpr="label" valueExpr="id" />
      </Column>;
    }
    else if (col.type === "checkbox") {
      return <Column alignment={"center"} dataType="boolean"
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return <Column key={col.dataIndex} type="buttons" dataField={col.dataIndex} caption={col.title} width={"auto"}>
      </Column>
    }
    else if (col.type === "date") {
      return <Column alignment={"left"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} format={'dd-MM-yyyy'}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex === "endDate" ? <AsyncRule
            message="end date cannot be less than start date"
            validationCallback={async (e) => {
              if (e && e.data) {
                if (e.data.startDate && e.data.endDate) {
                  return e.data.endDate >= e.data.startDate
                }
                else
                  return e.value >= e.data.startDate
              }

            }}
          /> : null
        }
      </Column>;
    }
    else if (col.type === "int") {
      return <Column alignment={"left"} dataType={col.type} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} editCellComponent={(props) => <CustomNumberBox props={props.data} canEdit={col.editable} isForTransfer={true} />}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex !== "id"
            ? <AsyncRule message={"Value should not exceed more than 15 digits"} validationCallback={async (e) => {
              return e && e.value && e.value.toString().length <= 15
            }} />
            : null
        }
      </Column>;
    }
    else if (col.type === "button") {
      return <Column alignment={"left"} allowEditing={true} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} editCellComponent={(props) => <SelectJD props={props.data} canEdit={col.editable} />} >
        <RequiredRule />
      </Column>;
    }
    else if (col.type === "file") {
      return <Column alignment={"left"} showInColumnChooser={false} allowEditing={col.editable} visible={col.visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} fixed={false} dataField={col.dataIndex} caption={col.title} cellRender={renderAttachment}
        editCellComponent={CustomFileInput} />
      {
        col.required ? <RequiredRule /> : null
      }
    }
    else if (col.type === "textarea") {
      return <Column alignment={"left"} editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else if (col.type === "text") {
      return <Column alignment={"left"} editCellComponent={CustomTextBox} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} cssClass={"WrappedColumnClass"}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else {
      if (col.dataIndex === "jobName") {
        return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
          type={onRowExpand ? "buttons" : "buttons"} dataField={col.dataIndex} caption={col.title} fixed={false}>
          <Button name="Job Title"
            component={(props) => {
              return <>
                {
                  props.data.data.jobName
                  /*props?.data?.data?.transactionType === "hc_transaction" ? <a href={`/views/jd/Job-${props?.data?.data?.jobCode ?? props?.data?.data?.jd_transaction?.jobCode}/${props?.data?.data?.version ?? props?.data?.data?.jd_transaction?.version}`} target={"_blank"}>
                    <u>{props.data.data.jobName}</u></a>
                    : props.data.data.jobName*/
                }
              </>
            }}
          />
          {
            col.required ? <RequiredRule /> : null
          }
        </Column>
      }
      else {
        return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
          {
            col.required ? <RequiredRule /> : null
          }
        </Column>;
      }
    }
  }

  /**
   * @param options
   * function use get verticals based on division and entity
   **/
  function getVerticalsByEntityAndDivision(options) {
    let uniqueVerticals = []
    if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && options.data.hasOwnProperty('masterOrgEntityId') && options.data.masterOrgEntityId !== null && options.data.hasOwnProperty('masterCountryId') && options.data.masterCountryId !== null) {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterCountryId === options.data?.masterCountryId && obj.masterOrgEntityId === options.data?.masterOrgEntityId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()];
    }
    else if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && options.data.hasOwnProperty('masterOrgEntityId') && options.data.masterOrgEntityId === null && options.data.hasOwnProperty('masterCountryId') && options.data.masterCountryId !== null) {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterCountryId === options.data?.masterCountryId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()];
    }
    else if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && options.data.hasOwnProperty('masterOrgEntityId') && options.data.masterOrgEntityId !== null) {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgEntityId === options.data?.masterOrgEntityId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()];
    }
    else if (isObjectEmpty(options)) {
      const filteredVerticals = dropDownData && dropDownData.hasOwnProperty('masterOrgVerticalId') ? dropDownData['masterOrgVerticalId'] : []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()];
    }
    else {
      const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['masterOrgVerticalId'], item])).values()];
    }

    return uniqueVerticals.length > 0 ? uniqueVerticals.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) : []

  }

  /**
   * @param options
   * function use get functions based on verticals
   **/
  function getFunctionsByVerticals(options) {
    let allUniqueValues = []
    if (isObjectEmpty(options)) {
      const filteredCap = dropDownData && dropDownData.hasOwnProperty('masterOrgFunctionId') ? dropDownData['masterOrgFunctionId'] : []
      allUniqueValues = [...new Map(filteredCap?.map(item => [item['masterOrgFunctionId'], item])).values()];
    }
    else {
      const filteredCap = dropDownData['masterOrgFunctionId'].filter(cap => cap.masterOrgVerticalId === options?.data?.masterOrgVerticalId && cap.masterOrgDivisionId === options?.data?.masterOrgDivisionId && cap.masterOrgEntityId === options?.data?.masterOrgEntityId && cap.masterCountryId === options?.data?.masterCountryId) ?? []
      allUniqueValues = [...new Map(filteredCap?.map(item => [item['masterOrgFunctionId'], item])).values()];

    }
    return allUniqueValues.length > 0 ? allUniqueValues.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())) : []
  }

  /**
   * @param options
   * function use get countries by division or entity
   **/
  function getCountriesByDivisionOrEntity(options) {
    let uniqueCountries = []
    if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && options.data.hasOwnProperty('masterOrgEntityId') && options.data.masterOrgEntityId !== null) {
      const filteredVerticals = dropDownData['masterCountryId'].filter(obj => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId && obj.masterOrgEntityId === options.data?.masterOrgEntityId) ?? []
      uniqueCountries = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }
    else if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null && !options.data.hasOwnProperty('masterOrgEntityId') || options.data?.masterOrgEntityId === null) {
      const filteredVerticals = dropDownData['masterCountryId'].filter(obj => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueCountries = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }
    else {
      const filteredVerticals = dropDownData && dropDownData.hasOwnProperty('masterCountryId') ? dropDownData['masterCountryId'] : []
      uniqueCountries = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
    }

    return uniqueCountries.length > 0 ? uniqueCountries.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())) : []

  }

  /**
   * @param options
   * function use get Entities by division
   **/
  function getEntitiesByDivision(options) {
    let uniqueEntities = []
    if (options && options.data && options.data.hasOwnProperty('masterOrgDivisionId') && options.data.masterOrgDivisionId !== null) {
      const filteredEntities = dropDownData['masterOrgEntityId']?.filter(d => d.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      uniqueEntities = [...new Map(filteredEntities?.map(item => [item['id'], item])).values()];
    }
    else {
      const filteredEntities = dropDownData && dropDownData.hasOwnProperty('masterOrgEntityId') ? dropDownData['masterOrgEntityId'] : []
      uniqueEntities = [...new Map(filteredEntities?.map(item => [item['id'], item])).values()];
    }

    return uniqueEntities.length > 0 ? uniqueEntities.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())) : []
  }

  function onRowPrepared(e) {
    if (e.data && (e.data.hasOwnProperty('cancelled') && e.data.cancelled === true || e?.data?.cStatus === 'Closed')) {
      e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      e.rowElement.className += " cancelled";
    }

    if (e.data && e.data.hasOwnProperty('needApproval') && e.data.needApproval) {
      e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      e.rowElement.className += " needApproval";
    } else if (e.data && e.data.hasOwnProperty('status') && e.data.status && !e?.data?.cancelled && e?.data?.cStatus !== 'On Hold') {
      e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      e.rowElement.className += " approved";
    }
    else if (e.data && e.data.hasOwnProperty('status') && e.data.status === false && !e?.data?.cancelled && !e?.data?.isDraft) {
      e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      e.rowElement.className += " rejected";
    }
  }

  /**
   * Handle Bulk Approve Requests
   **/
  const handleBulkApprove = async () => {
    const needApprovalTransferData = selectedRowKeys?.filter(obj => obj.needApproval && obj.transactionType === "transaction_transfer") ?? []
    if (needApprovalTransferData?.length)
      await bulkApproveOrReject(true, _.map(needApprovalTransferData, 'id'), 'transaction_transfer', masterModuleId, setSelectedRowKeys, apiCallBack, 'needApproval', false, selectedRowKeys)
    else
      toast.error(`Nothing to Action!`)
  }

  /**
   * Handle Bulk Reject Requests
   **/
  const handleBulkReject = async () => {
    const needApprovalTransferData = selectedRowKeys?.filter(obj => obj.needApproval && obj.transactionType === "transaction_transfer") ?? []
    if (needApprovalTransferData?.length)
      await bulkApproveOrReject(false, _.map(needApprovalTransferData, 'id'), 'transaction_transfer', masterModuleId, setSelectedRowKeys, apiCallBack)
    else
      toast.error(`Nothing to Action!`)
  }

  /**
 * Handle Bulk Reject Requests
 **/
  const handleBulkRollback = async () => {
    const data = selectedRowKeys.filter(sr => sr.needApproval && sr.status === null)
    if (data?.length)
      await bulkRollback(data, 'transaction_transfer', masterModuleId, setSelectedRowKeys, apiCallBack)
    else
      toast.error(`Nothing to Rollback!`)
  }

  /**
   * Handle Bulk Hold Requests
   **/
  const handleBulkHold = async (operation = true, isForApprover = false, key = "canHoldForApprover") => {
    const data = isForApprover
      ? selectedRowKeys.filter(item => item[key]).map(dataItem => {
        return {
          transactionType: dataItem.transactionType,
          id: dataItem.id,
          transferIds: dataItem.hasOwnProperty('transferIds') && dataItem?.transferIds?.length ? dataItem?.transferIds : dataItem.hasOwnProperty('repRApp') && dataItem?.repRApp?.length ? dataItem?.repRApp : []
        }
      })
      : selectedRowKeys.filter(item => item.status && item[key] && item.cancelled !== true).map(dataItem => {
        return {
          transactionType: dataItem.transactionType,
          id: dataItem.id
        }
      })

    if (data.length)
      await handleBulkHoldOrReActivateRequest(operation, data, isForApprover, apiCallBack)
    else
      toast.error(`Nothing to ${operation ? 'Hold' : 'Reactivate'}!`)
  }

  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData)
  }

  /**
   * get selected rows
   **/
  const hasSelected = selectedRowKeys.length > 0;

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      visible: permissions && permissions.canCreate && requestIdFilter !== null,
      options: {
        text: 'VIEW ALL SUPERVISORS',
        onClick: () => {
          localStorage.removeItem('rId');
          localStorage.removeItem('dgId');
          setrIdToFilter(null);
        }
      }
    },
      {
        location: 'after',
        widget: 'dxButton',
        visible: permissions && permissions.canCreate,
        options: {
          icon: 'add',
          onClick: () => navigate('/views/manage-supervisor')
        }
      });
  }

  // Function to update a specific row in the data source
  const updateRowInDataSource = (updatedRow) => {
    // Find the index of the row to be updated
    const rowIndex = dataSource.findIndex((row) => row.id === updatedRow.id);

    if (rowIndex !== -1) {
      // Update the specific row in the data source
      const updatedDataSource = [...dataSource];
      updatedDataSource[rowIndex] = updatedRow;
      // Update the state
      setDataSource(updatedDataSource);
      setTimeout(() => {
        dataGridRef?.current?.instance?.expandRow(updatedRow?.id)
      }, 200)
    }
  };

  const handleRowExpandCollapse = (e) => {
    onRowExpanding(e, true, null, false)
    setOnRowExpand(true)
    dataGridRef.current.instance.getDataSource().store().byKey(e?.key).done(async (rowData) => {
      if (rowData) {
        let transactionType = rowData?.transactionType ? rowData.transactionType : null
        let transactionID = rowData?.id ? rowData.id : null
        let delegatedUserId = rowData?.delegatedUserId ? rowData.delegatedUserId : null
        let hasDataLoaded = rowData.hasOwnProperty('hasDataLoaded') && rowData?.hasDataLoaded === true
        if (transactionType && transactionID && !hasDataLoaded) {
          rowData['hasDataLoaded'] = true
          rowData['data'] = await getTransferDetails(transactionType, transactionID, true, delegatedUserId)
          updateRowInDataSource(rowData)
        }
        else updateRowInDataSource(rowData)
      }
    });
  }

  return (
    <div id="all-transfer-data-grid">
      {hasSelected > 0
        ?
        <React.Fragment>
          <br />
          <MDAlert color="light">
            <MDTypography variant="subtitle2">
              {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
            </MDTypography>
            <div style={{ height: '30px', borderLeft: '2px solid #999', margin: '0 10px' }} />
            <MDBox>
              <Grid container spacing={2}>
                {
                  permissions && permissions.canApprove && selectedRowKeys.some(e => e.needApproval)
                    ? <Grid item>
                      <DEButton stylingMode={"contained"} type={"success"} text={"Approve"} onClick={handleBulkApprove} />
                    </Grid>
                    : null
                }
                <Grid item xs style={{ width: "300px" }}>
                  <ActionDropdown
                    permissions={permissions}
                    selectedRowKeys={selectedRowKeys}
                    transactionType={transactionType}
                    handleBulkReject={handleBulkReject}
                    handleBulkRollback={handleBulkRollback}
                    handleBulkHold={handleBulkHold}
                    handleBulkCancel={bulkCancelGroupRequest}
                    setPopupVisibility={setPopupVisibility}
                    callback={getAllTransfers}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </MDAlert>
        </React.Fragment>
        : null
      }

      {/* <CloneHeadCountPopup columns={columns} headcounts={selectedRowKeys} routeKey={routeKey} dropDownData={dropDownData} getTransferByIdDetails={_.map(selectedRowKeys, 'id')} pagesAndPermissions={pagesAndPermissions} dataAccess={dataAccess} setPopupVisibility={setPopupVisibility} isPopupVisible={isPopupVisible} /> */}

      <DataGrid
        onToolbarPreparing={onToolbarPreparing} onRowPrepared={onRowPrepared} /* columnMinWidth={150} */
        allowColumnResizing={true} dataSource={dataSource} keyExpr="id" showBorders={true}
        showColumnLines={true} showRowLines={true}
        disabled={isLoading} onSelectionChanged={onSelectionChanged}
        ref={dataGridRef}
        onCellClick={(e) => {
          if (e.column.dataField === "cStatus") {
            const grid = e.component;
            const rowKey = e.key;
            if (grid.isRowExpanded(rowKey)) {
              grid.collapseRow(rowKey);
            }
            else {
              grid.expandRow(rowKey);
            }
          }
        }}
        onRowExpanding={(e) => handleRowExpandCollapse(e)}
        onRowCollapsing={(e) => {
          //e?.component?.collapseRow(e?.key);
          setOnRowExpand(false)
          // setAutoWidth(true)
        }}>
        <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
        <LoadPanel enabled={isLoading} visible={isLoading} />
        <HeaderFilter visible={true} allowSearch={true} />
        <SearchPanel visible={true} />
        <Paging defaultPageSize={25} />
        <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
        <Export enabled={true} allowExportSelectedData={true} />
        <MasterDetail style={{ color: 'red' }} autoExpandAll={false} enabled={true} component={(props) => {
          try {
            return <CustomMasterDetail props={props} columns={transferFormColumns} dropDownData={dropDownData} transferDetails={props?.data?.data?.data ?? transferDetails} routeKey={routeKey} permissions={permissions} apiCallBack={apiCallBack} onRowExpand={onRowExpand} dataSource={dataSource} setDataSource={setDataSource} dgInstance={dataGridRef?.current?.instance} canApproveReject={!selectedRowKeys.filter(sr => sr.id === props.data.data.id).length} selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} pagesAndPermissions={pagesAndPermissions} />
          }
          catch (e) { }
        }} />
        {
          dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
        }
      </DataGrid>
    </div>
  );
})
AllTransfersDataGrid.displayName = "AllTransfersDataGrid"
export default AllTransfersDataGrid
