import request from "../services/Http"
import { useContext, useEffect } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING, SET_MANAGE_JOBS } from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIsLoading } from "./useIsLoading";
import { isJSONValid, getPageProperties, getTableColumns } from "../services/Helpers";
import { useNavigate } from "react-router-dom";
import { usePagination } from "./usePagination";

export default function useJobs(routeKey) {

    const [{ manageJobs, pagesAndPermissions }, dispatch] = useContext(Context)
    const { isLoading, setIsLoading } = useIsLoading()
    const { totalCount, setTotalCount, hasMore, setHasMore, page, setPage, pageSize, setPageSize} = usePagination()
    let navigate = useNavigate();


    useEffect(() => {
        return () => {
            setTotalCount(0)
            setHasMore(true)
            setPage(1)
            setPageSize(10)
            dispatch({
                type: SET_MANAGE_JOBS,
                payload: {}
            })
        }
    },[])

    async function getJobs(refresh = false) {
        //Not needed for now
       /* if (isLoading || !hasMore)
        {
            return;
        }*/

        setIsLoading(true)
        if (manageJobs?.rows?.length <= 0)
            dispatch({ type: SET_IS_LOADING, payload: true })

        try {
            const res = await request.get('jd-listing',{ params: { page, pageSize } })
            const properties = getPageProperties(pagesAndPermissions, routeKey)
            const table = getTableColumns(properties.page.table, routeKey)
            let columns = table.columns
            let rows = res.data.data
            let dropdownValues = isJSONValid(res.data.dropdownValues)

            setTotalCount(res.data.totalCount);
            setPage(page + 1);
            const fRows = manageJobs && manageJobs?.rows?.length && !refresh ? [...manageJobs.rows, ...rows] : rows

            if (res.data.totalCount <= pageSize) setHasMore(false)
            if (res.data.data.length === 0) setHasMore(false)

            rows.forEach((row) => {
                row.sequence = row.id
                row.index = row.id
                row.key = row.id
            })
            const dataTableData = { columns, rows: [...new Map(fRows.map(item => [item['id'], item])).values()], dropdownValues: dropdownValues }
            dispatch({ type: SET_MANAGE_JOBS, payload: dataTableData });
            dispatch({ type: SET_IS_LOADING, payload: false })
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            dispatch({ type: SET_IS_LOADING, payload: false })
        }
    }

    async function setJobsData(data) {
        const properties = getPageProperties(pagesAndPermissions, routeKey)
        const table = getTableColumns(properties.page.table, routeKey)
        let columns = table.columns
        let rows = data.data
        let dropdownValues = isJSONValid(data.dropdownValues)
        const fRows = manageJobs && manageJobs?.rows?.length ? [...manageJobs.rows, ...rows] : rows
        const dataTableData = { columns, rows: [...new Map(fRows.map(item => [item['id'], item])).values()], dropdownValues: dropdownValues }
        dispatch({ type: SET_MANAGE_JOBS, payload: dataTableData });
        dispatch({ type: SET_IS_LOADING, payload: false })
        setIsLoading(false)
    }

    async function deleteJobs(data) {
        try {
            dispatch({ type: SET_IS_LOADING, payload: true });
            dispatch({ type: SET_MANAGE_JOBS, payload: {} });
            const res = await request.delete('jd-listing', {
                data: {
                    data,
                    actionType: 'delete'
                }
            })
            toast.success(res.data.data);
        }
        catch (e) {}
    }

    async function approveOrRejectJobs(data, type) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.post('jd-listing/action', {
                data: {
                    action: data,
                    type: type
                }
            })
            if (res) {
                await getJobs()
                toast.success(res.data.data);
            }
        }
        catch (e) {}
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function deActivateJD(id){
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.put(`jd-listing/${id}`)
            setIsLoading(false)
            if (res){
                await getJobs()
                toast.success(res.data.data)
                navigate("/views/manage-jobs")
            }
        }
        catch (e) {}

        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    return {setJobsData, getJobs, deleteJobs, approveOrRejectJobs, deActivateJD, isLoading, hasMore, manageJobs }
}